<h2 class="popup-heading" mat-dialog-title>Create New Saved Search</h2>
<mat-dialog-content class="mat-typography">
    <div class="brands-view">
        <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput placeholder="Enter Name" [(ngModel)]="name" required>
        </mat-form-field>
        <ng-container *ngFor="let error of errors">
          <span> {{ error }} </span><br>
        </ng-container>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="cancel-popup" mat-button (click)="cancel()">Cancel</button>
  <button class="popup-save" mat-button (click)="confirm()" cdkFocusInitial [disabled]="processing">
    Save
    <mat-icon *ngIf="processing">
      <mat-spinner color="primary" diameter="20"></mat-spinner>
    </mat-icon>
  </button>
</mat-dialog-actions>