
  // This file was autogenerated by dynamically running set-environment.ts and using dotenv for managing API key secrecy
  export const environment = {
    production: false,
    site: window.location.hostname.indexOf('fireworks.ignitefiringsystems.com')!==-1  ? 'ignite' : 'wikifireworks',
    apiUrl: window.location.hostname.indexOf('fireworks.ignitefiringsystems.com')!==-1 ? 'https://' + (window.location.hostname.replace('fireworks.ignitefiringsystems.com', 'admin.ignitefiringsystems.com').replace('www.', '')) + '/api' : (window.location.hostname.indexOf('wikifireworks.com')!==-1 ? 'https://' + (window.location.hostname.replace('wikifireworks', 'api.wikifireworks').replace('www.', '')) + '/api' : 'http://localhost:8000/api'),
    appUrl: 'https://' + window.location.hostname,
    wsUrl: 'undefined',
    pusherAppKey: 'undefined',
    pusherAppCluster: 'undefined'
  };
