import {AlertService} from './alert.service';

declare var AppleID: any;
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {BehaviorSubject, Observable, of} from 'rxjs';
import { environment } from './../../environments/environment';

import {GoogleLoginProvider, SocialAuthService} from "@abacritt/angularx-social-login";
import {User} from "../interfaces/user.interface";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authorizePromise: Promise<boolean> | undefined;
  private readonly appleScriptUrl: string = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
  private ready: Promise<boolean> = new Promise(resolve => {
    if (typeof window !== 'undefined') {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = this.appleScriptUrl;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = (error: any) => resolve(false);
      document.getElementsByTagName('head')[0].appendChild(script);
    } else {
      resolve(false);
    }
  });
  isLoggedIn = new BehaviorSubject(false);
  token = new BehaviorSubject('');
  userSubject = new BehaviorSubject<User | undefined>(undefined);
  public user = this.userSubject.asObservable();
  // store the URL so we can redirect after logging in
  redirectUrl: string | null = null;
  apiUrl: string | null = null
  isAuthCheckedSubject = new BehaviorSubject(false);
  public isAuthChecked = this.isAuthCheckedSubject.asObservable();

  /**
   * Constructor
   * @param http The http client object
   */
  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private socialAuthService: SocialAuthService
  ) {
    this.apiUrl = environment.apiUrl;
    this.ready.then(isReady => {
      if (isReady) {
        this.init();
      }
    });
  }
  private init(): void {
    AppleID.auth.init({
      clientId: 'com.ignitefiringsystems.ignitesservice',
      scope: 'name email',
      redirectURI: 'https://'+ window.location.hostname +'/authenticationCallback.html',
      state: 'ignite',
      usePopup: true,
    });
  }

  apple_login(): Promise<any>{
    return AppleID.auth.signIn();
  }

  google_login(): Observable<any>{
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    return this.socialAuthService.authState;
  }

  process_google_login(user: any): Observable<any>{
    return this.http.post(this.apiUrl + '/login/google',{token: user.idToken, user_data: user});
  }

  process_apple_login(params: any): Observable<any>{
    return this.http.post(this.apiUrl + '/login/apple', params);
  }

  login(response: any): boolean {
    if(response.status){
      this.userSubject.next(response.user);
      this.isAuthCheckedSubject.next(true);
    }
    return response.status;
  }

  saveTags(user: any): Observable<any> {
    return this.http.post(this.apiUrl + '/general/tags', {tags: JSON.stringify(user.tags)});
  }
  saveSavedSearch(savedSearches: any): Observable<any>{
    return this.http.post(this.apiUrl + '/general/saved-searches', {saved_searches: JSON.stringify(savedSearches)});
  }
  authorize(): Promise<boolean> {
    if (!this.authorizePromise) {
      const url = environment.apiUrl + '/get-current-user';
      this.authorizePromise = this.http.post(url,{})
        .toPromise()
        .then( (resp:any) => {
          this.userSubject.next(resp.user);
          this.isAuthCheckedSubject.next(true);
          return true;
        })
        .catch(() => {
          this.isAuthCheckedSubject.next(true);
          return false;
        });
    }

    return this.authorizePromise;
  }
  logout(): Observable<any> {
    if(this.userSubject.value){
      if(this.userSubject.value.platform){
        this.socialAuthService.signOut().then(() => {
          this.isLoggedIn.next(false);
          this.userSubject.next(undefined);
        });
      }
    }
    return this.http.post(this.apiUrl + '/logout',{});
  }
}
