import { ChangeDetectorRef, Component, OnInit, Inject } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ColorPickerPopupComponent } from '../color-picker-popup/color-picker-popup.component';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { Color } from '@angular-material-components/color-picker';

@Component({
  selector: 'app-tags-popup',
  templateUrl: './tags-popup.component.html',
  styleUrls: ['./tags-popup.component.scss']
})
export class TagsPopupComponent implements OnInit {


  public user:any = null;
  public disabled = false;
  public color: ThemePalette = 'primary';
  public touchUi = true;
  public firework: any;
  tag: any = {
    name: '',
    color: null,
    fireworks: []
  }
  processing = false;

  constructor(public dialog: MatDialog,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) data:any,
    private dialogRef: MatDialogRef<TagsPopupComponent>) {
      this.firework = data.firework
      this.authService.user.subscribe((user: any) => {
        this.user = user;
        for(let i = 0; i < this.user.tags.length; i++){
          if(this.user.tags[i].color){
            const rgb = this.hexToRgb(this.user.tags[i].color);
            if(rgb){
              this.user.tags[i].color = new Color(rgb.r, rgb.g, rgb.b);
            }
          }
        }
      })
  }

  selected(index: number){
    return this.user.tags[index].fireworks.indexOf(this.firework.id) !== -1;
  }

  select(index: number){
    this.user.tags[index].fireworks.push(this.firework.id);
  }

  deselect(index: number){
    this.user.tags[index].fireworks.splice(this.user.tags[index].fireworks.indexOf(this.firework.id), 1);
  }

  remove(index: number){
    const dialogRef = this.dialog.open(ConfirmPopupComponent,{
      id: 'confirm-popup',
      maxWidth: '600px',
      width: '100%',
      data: {
        title: 'Remove Tag',
        message: 'Are you sure you want to remove this tag?'
      }
    });
    dialogRef.componentInstance.onConfirm.subscribe(() => {
      this.user.tags.splice(index);
      dialogRef.close();
    })
  }

  ngOnInit(): void {
  }
  add() {
		this.user.tags.push(this.tag);
    this.tag = {
      name: '',
      color: null,
      fireworks: []
    }
  }
  save(){
    if(this.processing) return;
    this.processing = true;
    for(let i = 0; i < this.user.tags.length; i++){
      if(this.user.tags[i].color){
        const color = "#" + this.user.tags[i].color.hex;
        delete this.user.tags[i].color
        this.user.tags[i].color = color
      }
    }
    this.authService.saveTags(this.user).subscribe((response: any) => {
      if(response.status){
        this.dialogRef.close();
      }
      else{

      }
      this.processing = false;
    }, (error: any) => {
      this.processing = false;
    });
  }
  hexToRgb(hex: string) {
		const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
		hex = hex.replace(shorthandRegex, (m, r, g, b) => {
			return r + r + g + g + b + b;
		});
		const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16)
		} : null;
	}

	invertColor(hex:any) {
    if(!hex) return '#000000';
    hex = hex.hex ?? hex;
  	if (hex.indexOf('#') === 0) {
			hex = hex.slice(1);
		}
		// convert 3-digit hex to 6-digits.
		if (hex.length === 3) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}
		if (hex.length !== 6) {
			throw new Error('Invalid HEX color.');
		}
		let r = parseInt(hex.slice(0, 2), 16),
			g = parseInt(hex.slice(2, 4), 16),
			b = parseInt(hex.slice(4, 6), 16);
		return (r * 0.299 + g * 0.587 + b * 0.114) > 186
			? '#000000'
			: '#FFFFFF';
	}

}
