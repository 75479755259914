<div *ngIf="!filtered()">
  <div class="banner-img" [innerHTML] = "page.content"></div>
    <div class="add-new-fireworks-box">
        <button mat-raised-button color="primary" class="add-btn custom-add-btn" (click)="addnewfirework()">Add New Firework <small *ngIf="!authUser" style="display: block;">(require login)</small></button>
        <p>You can add as many new fireworks as you want! Once added, the firework is available to only yourself within the website. You can use it for tagging and any further contributions. We may also approve the firework for general public use. If you don't see a brand, please email us at <a href="mailto:help@ignitefiringsystems.com">help@ignitefiringsystems.com</a> and we'll email you back quickly.</p>
    </div>
  <app-search-form></app-search-form>
  <app-saved-search-form></app-saved-search-form>
  <h3 class="summary-head">{{ defaults.total | number }} <a routerLink="contribute" target="_self">Fireworks</a> | {{ defaults.contributions | number }} <a routerLink="contributions" target="_self">Contributions</a> | {{ defaults.user_added | number }} Added by Public</h3>
  <div style="margin: 50px auto;" [hidden]="defaults.brands"><mat-progress-bar mode="query"></mat-progress-bar></div>
  <div class="brand-item-list" *ngIf="defaults.brands && (defaults.brands | callback : filterVerifiedBrands).length > 0">
      <div class="brand-item-list-title">
          <h3>
              Verified Brands
              <mat-icon matTooltip="All firework content for this brand was provided and verified by the manufacturer." matTooltipPosition="above" matTooltipClass="tooltip">help</mat-icon>
          </h3>
      </div>
      <div class="brand-item-list-content">
          <div class="brand-item" *ngFor="let brand of defaults.brands | callback : filterVerifiedBrands">
              <a (click)="selectBrand(brand.record.name)" #tooltip="matTooltip"
                 [matTooltip]="brand.record.name">
                  <app-image *ngIf="brand.record.logo" width="78" height="78" [key]="brand.record.logo"></app-image>
                  <mat-icon *ngIf="!brand.record.logo">broken_image</mat-icon>
                  <img src="/assets/img/verified.png" class="brand-verify-icon"/>
              </a>
          </div>
      </div>
  </div>
  <div class="brand-item-list" *ngIf="defaults.brands && (defaults.brands | callback : filterUnVerifiedBrands).length > 0">
      <div class="brand-item-list-title">
          <h3>
              Non-Verified Brands
              <mat-icon matTooltip="Fireworks content may not have been provided or verified by the manufacturer and may be missing or contain incorrect content." matTooltipPosition="above" matTooltipClass="tooltip">help</mat-icon>
          </h3>
      </div>
      <div class="brand-item-list-content">
          <div class="brand-item" *ngFor="let brand of defaults.brands | callback : filterUnVerifiedBrands">
            <a (click)="selectBrand(brand.record.name)" #tooltip="matTooltip"
               [matTooltip]="brand.record.name">
              <app-image *ngIf="brand.record.logo" width="78" height="78" [key]="brand.record.logo"></app-image>
              <mat-icon *ngIf="!brand.record.logo">broken_image</mat-icon>
            </a>
          </div>
      </div>
  </div>
</div>
<div *ngIf="filtered()">
  <app-search-result></app-search-result>
</div>
