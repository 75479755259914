import {Component, OnInit} from '@angular/core';
import {SolrService} from "../../services/solr.service";
import {FiltersService} from "../../services/filters.service";

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss']
})
export class BrandsComponent implements OnInit {

  defaults: any;
  filters: any;
  constructor(private solrService: SolrService,
              private filtersService: FiltersService) {
    this.filtersService.filters.subscribe((filters: any) => {
      this.filters = filters;
    });
    this.solrService.defaults.subscribe((defaults:any) => {
      this.defaults = defaults;
    })
  }

  ngOnInit(): void {
  }
  toggle(name:string, checked:boolean){
    let i = this.filters.brands.indexOf(name);
    if(checked && i === -1){
      this.filters.brands.push(name);
    }
    else if(!checked && i !== -1){
      this.filters.brands.splice(i, 1);
    }
    this.filtersService.setFilters(this.filters);
  }
  opened(){
    this.filters.expanded.brands = true;
    this.filtersService.setFilters(this.filters);
  }
  closed(){
    this.filters.expanded.brands = false;
    this.filtersService.setFilters(this.filters);
  }

}
