import { Component, OnInit, AfterViewInit, Input, SimpleChanges } from '@angular/core';
import { map, shareReplay, filter, mergeMap } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import {DataService} from "../services/data.service";
import {AuthService} from "../services/auth.service";
import {environment} from "../../environments/environment";
import {SolrService} from "../services/solr.service";
import {FiltersService} from "../services/filters.service";
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, AfterViewInit {
  env: any;
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  public settings:any = {};
  public user:any = null;
  public defaults: any;
  public filters:any;
  
  @Input() fullPageTemplate: any;

  constructor( private breakpointObserver: BreakpointObserver,
               private dataService: DataService,
               public authService: AuthService,
               private solrService: SolrService,
               private filtersService: FiltersService,
               private router: Router,
               private route: ActivatedRoute) {
    this.env = environment;
    this.dataService.settings.subscribe((settings: any) => {
      this.settings = settings
    });
    this.authService.authorize();
    this.authService.user.subscribe(user => {
      this.user = user;
    })
    this.filtersService.filters.subscribe((filters:any) => {
      this.filters = filters;
    })
    this.solrService.defaults.subscribe((defaults:any) => {
      this.defaults = defaults;
    })
  }

  ngOnChanges( changes: SimpleChanges ){
    console.log(changes['fullPageTemplate'].currentValue);
  }

  getObjectById(objects: [], id: number):any{
    return objects.find((item:any) => item.record.id === id);
  }
  remove(name:string, filter: string){
    let i = this.filters[filter].indexOf(name);
    if(i !== -1){
      this.filters[filter].splice(i, 1);
      this.filtersService.setFilters(this.filters);
    }
  }

  removeMinDuration(){
    this.filters.duration.min = 0
    this.filtersService.setFilters(this.filters);
  }

  removeMaxDuration(){
    this.filters.duration.max = 60
    this.filtersService.setFilters(this.filters);
  }

  ngAfterViewInit() {
    
  }
  ngOnInit(){
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      map(() => this.route),
      map((route: any) => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter((route: any) => route.outlet === 'primary'),
      mergeMap((route: any) => route.data)
    ).subscribe((data: any) => {
        this.fullPageTemplate = data['fullPageTemplate'] ? true : false
        console.log(this.fullPageTemplate);
      }
    )
  }
  logout() {
    this.authService.logout().subscribe(res => {
      window.location.reload();
    });
  }
}
