import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SolrService} from "../../services/solr.service";
import {FiltersService} from "../../services/filters.service";
import {debounceTime, distinctUntilChanged, fromEvent, Observable} from "rxjs";
import {filter, map, startWith, switchMap, tap} from "rxjs/operators";
import { UntypedFormControl } from '@angular/forms';
import {Router} from "@angular/router";

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit {

  defaults: any;
  filters: any;
  searchControl = new UntypedFormControl();
  filteredData: any;

  constructor(private solrService: SolrService,
    private filtersService: FiltersService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.filtersService.filters.subscribe((filters: any) => {
      this.filters = filters;
      if(this.filters.search != this.searchControl.value){
        this.searchControl.setValue(this.filters.search);
      }
    });

    this.solrService.defaults.subscribe((defaults:any) => {
      this.defaults = defaults;
    });

    this.searchControl.valueChanges
      .pipe(
        debounceTime(200),
        tap(() => {
          this.filteredData = [];
        }),
        switchMap(value => this.solrService.getSuggestion({search: value}))
      )
      .subscribe((data: any) => {
        this.filteredData = data;
      });
  }

  public autoCompleteFilter(suggestion: any) {
    let i;
    this.filters.is_name = false;
    switch(suggestion.type){
      case 'Brand':
        i = this.filters.brands.indexOf(suggestion.term);
        if(i === -1){
          this.filters.brands.push(suggestion.term);
        }
        this.filters.search = '';
        break;
      case 'Type':
        i = this.filters.types.indexOf(suggestion.term);
        if(i === -1){
          this.filters.types.push(suggestion.term);
        }
        this.filters.search = '';
        break;
      case 'Country':
        i = this.filters.countries.indexOf(suggestion.term);
        if(i === -1){
          this.filters.countries.push(suggestion.term);
        }
        this.filters.search = '';
        break;
      case 'FireworkName':
        this.filters.search = suggestion.term;
        this.filters.is_name = true;
        break;
    }
    this.filtersService.setFilters(this.filters);
  }

  searchFreeText(){
    this.filters.search = this.searchControl.value;
    this.filtersService.setFilters(this.filters);
  }

  clear(){
    this.filters.search = '';
    this.filtersService.setFilters(this.filters);
  }

  ngAfterViewInit() {  }

  toggle(name:string, checked:boolean){
    this.filters[name] = checked;
    this.filtersService.setFilters(this.filters);
  }
}
