<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="banner-content-update">
                <img src="assets/img/lfY3ouycPniqTlSWWbXuNFpGpO7ybt7AYZnYALt9.png"/>
            </div>
            <div class="content-area-udpdate">
                <div class="row">
                    <div class="col-md-3">
                        <mat-accordion class="sidebar-list" multi>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                <mat-panel-title>Recent Content Updates</mat-panel-title>
                                </mat-expansion-panel-header>
                            
                               <a href="#">Dec 30th, 2021</a>
                               <a href="#">Dec 30th, 2021</a>
                               <a href="#">Dec 30th, 2021</a>
                            
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                <mat-panel-title>Categories</mat-panel-title>
                                </mat-expansion-panel-header>
                            
                                <a href="#">Added Fireworks</a>
                            
                            </mat-expansion-panel>
                        </mat-accordion>
                    </div>
                    <div class="col-md-9">
                        <div class="post clearfix">
                            <h3 class="page-title">
                                <a href="#" target="_self">December 2021 Update</a>
                                <small class="meta">Posted on Dec 30th, 2021. Categories</small>
                            </h3>
                            <a href="#" target="_self"><img src="assets/img/YhUg69J9ApvD2gSpyuMYDcB6FNV8ZfUb62mPnzrM.png" style="max-width: 20%; float:left; margin-right: 15px; margin-bottom: 15px"></a>
                            <p>For our December update, we have added two new brands: <a href="#" target="_self" rel="noopener">Liberty Bell</a> and <a href="#" target="_self" rel="noopener">Engelsrud</a>.&nbsp;For any questions or requests please <a href="#" target="_self">Contact Us</a>.</p>
                            <a href="#" class="btn btn-primary" style="min-width: 200px;" target="_self">Learn More</a>
                        </div>
                        <div class="post clearfix">
                            <h3 class="page-title">
                                <a href="#" target="_self">December 2021 Update</a>
                                <small class="meta">Posted on Dec 30th, 2021. Categories</small>
                            </h3>
                            <a href="#" target="_self"><img src="assets/img/YhUg69J9ApvD2gSpyuMYDcB6FNV8ZfUb62mPnzrM.png" style="max-width: 20%; float:left; margin-right: 15px; margin-bottom: 15px"></a>
                            <p>For our December update, we have added two new brands: <a href="#" target="_self" rel="noopener">Liberty Bell</a> and <a href="#" target="_self" rel="noopener">Engelsrud</a>.&nbsp;For any questions or requests please <a href="#" target="_self">Contact Us</a>.</p>
                            <a href="#" class="btn btn-primary" style="min-width: 200px;" target="_self">Learn More</a>
                        </div>
                        <div class="post clearfix">
                            <h3 class="page-title">
                                <a href="#" target="_self">December 2021 Update</a>
                                <small class="meta">Posted on Dec 30th, 2021. Categories</small>
                            </h3>
                            <a href="#" target="_self"><img src="assets/img/YhUg69J9ApvD2gSpyuMYDcB6FNV8ZfUb62mPnzrM.png" style="max-width: 20%; float:left; margin-right: 15px; margin-bottom: 15px"></a>
                            <p>For our December update, we have added two new brands: <a href="#" target="_self" rel="noopener">Liberty Bell</a> and <a href="#" target="_self" rel="noopener">Engelsrud</a>.&nbsp;For any questions or requests please <a href="#" target="_self">Contact Us</a>.</p>
                            <a href="#" class="btn btn-primary" style="min-width: 200px;" target="_self">Learn More</a>
                        </div>
                        <div class="post clearfix">
                            <h3 class="page-title">
                                <a href="#" target="_self">December 2021 Update</a>
                                <small class="meta">Posted on Dec 30th, 2021. Categories</small>
                            </h3>
                            <a href="#" target="_self"><img src="assets/img/YhUg69J9ApvD2gSpyuMYDcB6FNV8ZfUb62mPnzrM.png" style="max-width: 20%; float:left; margin-right: 15px; margin-bottom: 15px"></a>
                            <p>For our December update, we have added two new brands: <a href="#" target="_self" rel="noopener">Liberty Bell</a> and <a href="#" target="_self" rel="noopener">Engelsrud</a>.&nbsp;For any questions or requests please <a href="#" target="_self">Contact Us</a>.</p>
                            <a href="#" class="btn btn-primary" style="min-width: 200px;" target="_self">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>