<app-search-form></app-search-form>
<app-saved-search-form></app-saved-search-form>
<div class="tags" *ngIf="user">
    <mat-chip-listbox selectable multiple [(ngModel)]="tags" (change)="tagSelect()">
        <mat-chip-option [style.background-color]="tag.color ?? '#ffffff'" *ngFor="let tag of user.tags; let i = index" [value]="i">
            <span [style.color]="invertColor(tag.color)">{{ tag.name }} ({{ tag.fireworks.length }})</span>
        </mat-chip-option>
    </mat-chip-listbox>
</div>
<div class="spinner-container" [hidden]="!(dataSource.loading$ | async)">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<h3 [hidden]="(dataSource.loading$ | async)">Search result - {{ dataSource.count$ | async }} Results</h3>
<div class="search-table" [hidden]="(dataSource.loading$ | async)">
  <table mat-table [dataSource]="dataSource" class="w-100" matSort>
    <ng-container matColumnDef="score">
      <th style="width:10%;" mat-header-cell mat-sort-header *matHeaderCellDef [hidden]="!user"></th>
      <td class="column-primary" mat-cell *matCellDef="let element" [hidden]="!user">
        <div class="tags">
          <a (click)="openTag(element)">Tag</a>
          <a (click)="openContribution(element)">Contribute</a>
          <mat-chip-listbox aria-label="Firework Tags" *ngIf="user">
            <ng-container *ngFor="let tag of user.tags">
                <mat-chip [style.background-color]="tag.color ?? '#ffffff'" *ngIf="tag.fireworks.indexOf(element.id) !== -1" (click)="openTag(element)">
                    <span [style.color]="invertColor(tag.color)">{{ tag.name }}</span>
                </mat-chip>
            </ng-container>
          </mat-chip-listbox>
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td class="column-primary" mat-cell *matCellDef="let element"><a [routerLink]="['/firework/' + element.brand_slug + '/' + element.slug]">{{element.name}}</a>
        <div class="tags csc-firework" *ngIf="showAddToFireworksButton">
          <a (click)="addToFireworkIds(element)" *ngIf="fireworkIds.indexOf(element.id)===-1">Add to My Fireworks</a>
          <a href="javascirpt:void()" *ngIf="fireworkIds.indexOf(element.id)!==-1" class="disabled">Added to My Fireworks</a>
        </div>
        <span class="table-toggle-icon"><mat-icon>arrow_drop_down</mat-icon></span></td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="video">
      <th style="width:10%;" mat-header-cell *matHeaderCellDef> Video </th>
      <td data-colname="Video" style="text-align:center;" mat-cell *matCellDef="let element">
        <a (click)="video_popup(element)" class="youtube-icon" *ngIf="element.video || hasContribution('video', element)"><mat-icon>play_circle_filled</mat-icon></a>
        <span *ngIf="!element.video && !hasContribution('video', element)">No Video</span>
        
        <span *ngIf="hasContribution('video', element)" (click)="openContribution(element)" class="contribution-display-link"></span>
      </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef> Image </th>
      <td data-colname="Image" mat-cell *matCellDef="let element">
        <a (click)="image_popup(element)" *ngIf="element.image || hasContribution('image', element)">
          <app-image width="120" [key]="(element.image ? (hasContribution('image', element, true) ? getContribution('image', element, true) : element.image) : getContribution('image', element))"></app-image>
        </a>
        
        <span *ngIf="!element.image && !hasContribution('image', element)">No Image</span>
        <span *ngIf="hasContribution('image', element)" (click)="openContribution(element)" class="contribution-display-link"></span>
      </td>
    </ng-container>

    <!-- Brand Column -->
    <ng-container matColumnDef="brand">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Brand </th>
      <td data-colname="Brand" mat-cell *matCellDef="let element"> {{ element.brand ?? '--'}} </td>
    </ng-container>

    <!-- Brand Column -->
    <ng-container matColumnDef="firework_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
      <td data-colname="Type" mat-cell *matCellDef="let element"> {{ element.firework_type ?? '--'}} </td>
    </ng-container>

    <!-- Brand Column -->
    <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Duration </th>
      <td data-colname="Duration" mat-cell *matCellDef="let element"> {{ element.duration ? element.duration + ' secs' : 'No Duration'}} 
        <span *ngIf="hasContribution('duration', element)" (click)="openContribution(element)" class="contribution-display-link"></span>
        </td>
    </ng-container>

    <ng-container matColumnDef="part_number">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Part Number </th>
      <td data-colname="Part Number" mat-cell *matCellDef="let element"> {{element.part_number}} </td>
    </ng-container>

    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
      <td data-colname="Description" mat-cell *matCellDef="let element"> {{element.description}}
        <span *ngIf="hasContribution('description', element)" (click)="openContribution(element)" class="contribution-display-link"></span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell no-results-cell" colspan="9">No data found matching the selected filters.</td>
    </tr>
  </table>
  <div class="list-footer" [hidden]="(dataSource.count$ | async) == 0">
    <mat-paginator [pageSizeOptions]="[25, 50, 100, 1000]" [length]="dataSource.count$ | async" [pageSize]="25" aria-label="Select page of the records."></mat-paginator>
  </div>
</div>
