import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CscService {
  url = 'https://api.cobrashowcreator.com/fireworks/add/wiki';
  token: any = null;

  showAddToFireworksButtonSubject = new BehaviorSubject<boolean>(false);
  showAddToFireworksButton = this.showAddToFireworksButtonSubject.asObservable();

  addedFireworkIdsSubject = new BehaviorSubject<any>([]);
  addedFireworkIds = this.addedFireworkIdsSubject.asObservable();

  constructor(private http:HttpClient) {
    this.token = this.readCookie('wiki_token');
    if(environment.site == 'ignite'){
      this.url = 'https://api.ignitefiringsystems.com/fireworks/add/wiki'
    }
    if(!this.token){
        //this.token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVVUlEIjoiRTkxOUJENjgiLCJpYXQiOjE2MDUwNTU5NTV9.71Op87toz_qLgC9Kh0im5tu9HD68dkpGOcZs8WOupvA';
    }
    if(this.token){
        this.showAddToFireworksButtonSubject.next(true);
        this.getAlreadyAddedFireworkIds().subscribe((response: any) => {
            if(response.wiki_firework_ids){
                this.addedFireworkIdsSubject.next(response.wiki_firework_ids);
            }
        }, (error: any) => {
            //this.addedFireworkIdsSubject.next([28300]);
        });
    }
  }

  getAlreadyAddedFireworkIds(): Observable<any>{
    return this.http.get(this.url + "/fireworks?wiki_token=" + this.token);
  }

  addToMyFirework(fireworkId: any, user_id: any): Observable<any>{
    return this.http.post(this.url + "/" + fireworkId,{wiki_token: this.token, wiki_user_id: user_id});
  }

  addAlreadyAddedFireworkIds(firework_id: any){
    try {
        if (window.opener != undefined) {
            window.opener.postMessage('reload_my_fireworks_list', '*'); //NEW
        }
    }catch(error) {

    }
    let fireworkIds = this.addedFireworkIdsSubject.getValue();
    fireworkIds.push(firework_id);
    this.addedFireworkIdsSubject.next(fireworkIds);
  }

  storeWikiToken(token: any){
    this.createCookie("wiki_token", token, 60); //60 days
  
    if (token != undefined) { 
      this.token = token;
      this.showAddToFireworksButtonSubject.next(true);
    }
  }
  createCookie(name: any, value: any, days: any) {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  }
  readCookie(name: any){
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for(let i=0;i < ca.length;i++) {
        let c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  eraseCookie(name: any) {
    this.createCookie(name,"",-1);
  }
}
