import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SolrService} from "../../services/solr.service";
import {FiltersService} from "../../services/filters.service";

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class CountryComponent implements OnInit {
  defaults: any;
  filters: any;
  constructor(private solrService: SolrService,
              private filtersService: FiltersService) {
    this.filtersService.filters.subscribe((filters: any) => {
      this.filters = filters;
    });
    this.solrService.defaults.subscribe((defaults:any) => {
      this.defaults = defaults;
    })
  }

  ngOnInit(): void {
  }
  toggle(name:string, checked:boolean){
    let i = this.filters.countries.indexOf(name);
    if(checked && i === -1){
      this.filters.countries.push(name);
    }
    else if(!checked && i !== -1){
      this.filters.countries.splice(i, 1);
    }
    this.filtersService.setFilters(this.filters);
  }
  opened(){
    this.filters.expanded.countries = true;
    this.filtersService.setFilters(this.filters);
  }
  closed(){
    this.filters.expanded.countries = false;
    this.filtersService.setFilters(this.filters);
  }
}
