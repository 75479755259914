import { NgModule } from '@angular/core';
import {BrowserModule, Meta, Title} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutComponent } from './layout/layout.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import { HomeComponent } from './home/home.component';
import { DurationComponent } from './widgets/duration/duration.component';
import { CountryComponent } from './widgets/country/country.component';
import { RegulationsComponent } from './widgets/regulations/regulations.component';
import { TypesComponent } from './widgets/types/types.component';
import { BrandsComponent } from './widgets/brands/brands.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { SearchResultComponent } from './search-result/search-result.component';
import {MatTableModule} from '@angular/material/table';
import { SinglePageComponent } from './single-page/single-page.component';
import { LoginComponent } from './login/login.component';
import {MatSliderModule} from '@angular/material/slider';
import { ContentUpdatesComponent } from './content-updates/content-updates.component';
import { SearchFormComponent } from './widgets/search-form/search-form.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {httpInterceptorProviders} from "./http-interceptors";
import {HttpClientModule} from "@angular/common/http";
import {MatChipsModule} from '@angular/material/chips';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ContributionListComponent } from './search-result/contribution-list/contribution-list.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs';
import {AlertComponent} from "./alert/alert.component";
import {GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule, GoogleSigninButtonModule} from "@abacritt/angularx-social-login";
import { SavedSearchFormComponent } from './widgets/saved-search-form/saved-search-form.component';
import {CallbackPipe} from "./pipes/callback.pipe";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSortModule} from "@angular/material/sort";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import { ImagePopupComponent } from './common/image-popup/image-popup.component';
import { VideoPopupComponent } from './common/video-popup/video-popup.component';
import { TagsPopupComponent } from './common/tags-popup/tags-popup.component';
import { ImageComponent } from './common/image/image.component';
import { ColorPickerPopupComponent } from './common/color-picker-popup/color-picker-popup.component';
import { DefaultComponent } from './default/default.component';
import { ConfirmPopupComponent } from './common/confirm-popup/confirm-popup.component';
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
import { NewSavedSearchComponent } from './widgets/saved-search-form/new-saved-search/new-saved-search.component';
import { ResizeService } from './services/resize.service';
import { LoadingPopupComponent } from './common/loading/loading.component';
import { AddNewFireworkComponent } from './widgets/add-new-firework/add-new-firework.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    HomeComponent,
    DurationComponent,
    CountryComponent,
    RegulationsComponent,
    TypesComponent,
    BrandsComponent,
    SearchResultComponent,
    SinglePageComponent,
    LoginComponent,
    ContentUpdatesComponent,
    SearchFormComponent,
    ContributionListComponent,
    AlertComponent,
    SavedSearchFormComponent,
    CallbackPipe,
    ImagePopupComponent,
    VideoPopupComponent,
    TagsPopupComponent,
    ImageComponent,
    ColorPickerPopupComponent,
    DefaultComponent,
    ConfirmPopupComponent,
    NewSavedSearchComponent,
    LoadingPopupComponent,
    AddNewFireworkComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatToolbarModule,
    MatExpansionModule,
    MatIconModule,
    MatFormFieldModule,
    MatRadioModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTableModule,
    MatSliderModule,
    MatMenuModule,
    MatSidenavModule,
    MatListModule,
    MatChipsModule,
    MatTooltipModule,
    MatDialogModule,
    MatTabsModule,
    SocialLoginModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    GoogleSigninButtonModule,
    NgxMatColorPickerModule
  ],
  providers: [
    httpInterceptorProviders,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '1086582487410-su5n9akki98a5hpv9rbic0sgmr02k0gk.apps.googleusercontent.com'
            )
          }
        ]
      } as SocialAuthServiceConfig,
    },
    Title,
    Meta,
    { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS },
    ResizeService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
