import { Component, OnInit, OnDestroy } from '@angular/core';
import {SolrService} from "../services/solr.service";
import {FiltersService} from "../services/filters.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DataService} from "../services/data.service";
import { MatDialog } from '@angular/material/dialog';
import { AddNewFireworkComponent } from '../widgets/add-new-firework/add-new-firework.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  page:any = {};
  public defaults: any;
  public filters:any;
  public searching = false;
  loaded = false;
  subscriptions: any = [];
  authUser: any;
  constructor(private solrService: SolrService, private _snackBar: MatSnackBar,
              private filtersService: FiltersService, private router: Router,
              private route: ActivatedRoute, private authService: AuthService,
              private dataService: DataService, public dialog: MatDialog) {
    this.authService.user.subscribe(user => {
      this.authUser = user;
    });
    this.subscriptions.push(this.dataService.settings.subscribe((setting) => {
      if(setting.pages){
        this.page = setting.pages.home;
      }
    }));
    this.subscriptions.push(this.filtersService.filters.subscribe((filters:any) => {
      this.filters = filters;
    }));
    this.subscriptions.push(this.solrService.defaults.subscribe((defaults:any) => {
      this.defaults = defaults;
    }));
    this.filtersService.setFiltersLoaded(false);
    this.subscriptions.push(this.route.queryParams.subscribe(params => {
      if(params['brands']){
        this.filters.brands = params['brands'].split(',');
      }
      else{
        this.filters.brands = [];
      }
      if(params['types']){
        this.filters.firework_types = params['types'].split(',');
      }
      else{
        this.filters.firework_types = [];
      }
      if(params['countries']){
        this.filters.countries = params['countries'].split(',');
      }
      else{
        this.filters.countries = [];
      }
      if(params['regulations']){
        this.filters.regulations = params['regulations'].replace('Consumer (UN0336)', 0).replace('Professional (UN0431)', 1).split(',');
        if(this.filters.regulations[0] === '1'){
          this.filters.regulations[0] = 1;
        }
        else{
          this.filters.regulations[0] = 0;
        }
        if(this.filters.regulations.length === 2){
          if(this.filters.regulations[1] === '1'){
            this.filters.regulations[1] = 1;
          }
          else{
            this.filters.regulations[1] = 0;
          }
        }
      }
      else{
        this.filters.regulations = [];
      }
      this.filters.has_video = params['has_video'] ?? false;
      this.filters.has_duration = params['has_duration'] ?? false;
      this.filters.is_name = false;
      if(params['name']){
        this.filters.search = params['name'];
        this.filters.is_name = true;
      }
      else if(params['s']){
        this.filters.search = params['s'];
      }
      else{
        this.filters.search = '';
      }
      this.filtersService.setFilters(this.filters);
      this.filtersService.setFiltersLoaded(true);
      if(!this.filtered()){
        this.solrService.getDefaults();
      }
    }));
  }

  filterVerifiedBrands(brand: any) {
    return brand.record.verified
  }

  filterUnVerifiedBrands(brand: any) {
    return !brand.record.verified
  }

  filtered():boolean{
    return this.filters.duration.min > 0
      || this.filters.duration.max < 60
      || this.filters.countries.length > 0
      || this.filters.regulations.length > 0
      || this.filters.firework_types.length > 0
      || this.filters.brands.length > 0
      || this.filters.search != ''
      || this.filters.has_video
      || this.filters.has_duration
  }

  selectBrand(id: number): void{
    this.filters.brands.push(id);
    this.filtersService.setFilters(this.filters);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    for(let subscription of this.subscriptions){
      subscription.unsubscribe();
    }
  }

  addnewfirework(){
    if(this.authUser === undefined) { this.router.navigate(['/login']); return;};
    
    const dialogRef = this.dialog.open(AddNewFireworkComponent,{
			maxWidth: '600px',
			width: '100%',
		});

    dialogRef.afterClosed().subscribe(result => {
			if (result){
        this._snackBar.open('Firework added successfully.', 'Close');
      } 
		});

  }
}
