import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-image-popup',
  templateUrl: './image-popup.component.html',
  styleUrls: ['./image-popup.component.scss']
})
export class ImagePopupComponent implements OnInit {
  image: any;
  title: any;
  constructor(@Inject(MAT_DIALOG_DATA) data:any) {
    this.image = data.image
    console.log(this.image);
    this.title = data.title
  }

  ngOnInit(): void {
  }

}
