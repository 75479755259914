import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SearchResultComponent } from './search-result/search-result.component';
import { SinglePageComponent } from './single-page/single-page.component';
import { LoginComponent } from './login/login.component';
import { ContentUpdatesComponent } from './content-updates/content-updates.component';
import { DefaultComponent } from './default/default.component';

const routes: Routes = [

  { path: '', component: HomeComponent },
  { path: 'firework/:brand/:firework', component: SinglePageComponent },
  {
    path: 'login', component: LoginComponent,
     data:{
      fullPageTemplate: true
    }
  },
  { path: '**', component: DefaultComponent,data:{
    fullPageTemplate: true
  }},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Tell the router to use the hash instead of HTML5 pushstate.
    useHash: true,

    // Enable the Angular 6+ router features for scrolling and anchors.
    scrollPositionRestoration: "enabled",
    anchorScrolling: "enabled",
    enableTracing: false
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
