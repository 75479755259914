import { Component, OnInit, Inject, HostListener, Renderer2 } from '@angular/core';
import {DataService} from "./services/data.service";
import {AuthService} from "./services/auth.service";
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import {environment} from "../environments/environment";
import { DOCUMENT } from '@angular/common';
import { CscService } from './services/csc.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SocialAuthService } from '@abacritt/angularx-social-login';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'IgniteFiringSystems';
  loaded = false;
  error = false;
  fullPageTemplate = true;
  processing = false;

  @HostListener('window:message', ['$event'])
  onMessage(event: any) {
    console.log('message', event);
    if (event.origin.indexOf('localhost') == -1 && event.origin.indexOf('cobrashowcreator') == -1 && event.origin.indexOf('cobrafiringsystems') == -1 && event.origin.indexOf('elevatepyro') == -1 && event.origin.indexOf('ignitefiringsystems') == -1) { return; }
    
    if (event.data.message == 'store_wiki_token' && event.data.wiki_token != null) {
      console.log('store_wiki_token', event)
      this.cscService.storeWikiToken(event.data.wiki_token)
    }
  }

  constructor(private dataService: DataService,
              public authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              @Inject(DOCUMENT) private _document: HTMLDocument,
              private cscService: CscService,
              private _snackBar: MatSnackBar,
              private socialAuthService: SocialAuthService,
              private renderer: Renderer2,
              @Inject(DOCUMENT) private document: Document) {
    this._document.getElementById('appFavicon')?.setAttribute('href', '/assets/img/' + environment.site + '-favicon.png');

    this.authService.isAuthChecked.subscribe((status: any) => {
      //this.loaded = status;
    })
    this.dataService.settings.subscribe((setting: any) => {
      if(typeof setting === 'boolean'){
        this.loaded = true;
        this.error = true;
      }
      else if(setting.pages){
        this.loaded = true;
        this.error = false;
      }
    });
    this.dataService.getSettings();
  }
  ngOnInit(){
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.defer = true;
    script.setAttribute('data-domain', environment.site == 'ignite' ? 'fireworks.ignitefiringsystems.com' : 'wikifireworks.com');
    script.src = 'https://plausible.io/js/script.js';
    this.renderer.appendChild(this.document.head, script);
    this.socialAuthService.authState.subscribe((user: any) => {
      if(user && !this.processing){
        this.processing = true;
        this.authService.process_google_login(user).subscribe((response: any) => {
          this.processing = false;
          if(this.authService.login(response)){
            this.router.navigate(['']);
          }
        }, (error: any) => {
          this.authService.logout().subscribe(() => {
            this.processing = false;
          });
          this._snackBar.open('Error signing in with Google. Try again', 'Close');
        });
      }
    });
  }

  google_login(): void {
    this.authService.google_login().subscribe((user: any) => {
      if(user && !this.processing){
        this.processing = true;
        this.authService.process_google_login(user).subscribe((response: any) => {
          this.processing = false;
          if(this.authService.login(response)){
            this.router.navigate(['']);
          }
        }, (error: any) => {
          this.authService.logout().subscribe(() => {
            this.processing = false;
          });
          this._snackBar.open('Error signing in with Google. Try again', 'Close');
        });
      }
    }, (error: any) => {
      this._snackBar.open('Error signing in with Google. Try again', 'Close');
    });
  }

  reload(){
    window.location.reload();
  }
}
