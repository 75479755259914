import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-video-popup',
  templateUrl: './video-popup.component.html',
  styleUrls: ['./video-popup.component.scss']
})
export class VideoPopupComponent implements OnInit {
  video: any;
  videoEmbedURL: any;
  title: any;
  constructor(@Inject(MAT_DIALOG_DATA) data:any,
              private sanitizer: DomSanitizer) {
    this.video = data.video;
    console.log(this.video);
    if(this.video.indexOf('youtu.be') !== -1){
      this.videoEmbedURL = sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.video.substring(this.video.lastIndexOf('/')+1) + "/?autoplay=1")
    }
    else{
      this.videoEmbedURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.video)
    }
    this.title = data.title
  }

  ngOnInit(): void {
  }

}
