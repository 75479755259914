import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SolrService} from "../../services/solr.service";
import {FiltersService} from "../../services/filters.service";

@Component({
  selector: 'app-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.scss']
})
export class DurationComponent implements OnInit {
  min = 0;
  max = 60;
  defaults: any;
  filters: any;
  constructor(private solrService: SolrService,
              private filtersService: FiltersService) {
    this.filtersService.filters.subscribe((filters: any) => {
      this.filters = filters;
    });
    this.solrService.defaults.subscribe((defaults:any) => {
      this.defaults = defaults;
    })
  }

  ngOnInit(): void {
  }

  changed(){
    this.filtersService.setFilters(this.filters);
  }
  opened(){
    this.filters.expanded.duration = true;
    this.filtersService.setFilters(this.filters);
  }
  closed(){
    this.filters.expanded.duration = false;
    this.filtersService.setFilters(this.filters);
  }

}
