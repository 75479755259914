import {AfterViewInit, Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SolrService} from "../services/solr.service";
import {Firework} from "../interfaces/firework.interface";
import {Location} from "@angular/common";
import {ImagePopupComponent} from "../common/image-popup/image-popup.component";
import {VideoPopupComponent} from "../common/video-popup/video-popup.component";
import {MatDialog} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {ContributionsDataSource} from "../services/datasources/contributions.datasource";
import {FireworksDataSource} from "../services/datasources/fireworks.datasource";
import {merge} from "rxjs";
import {tap} from "rxjs/operators";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {ContributionService} from "../services/contribution.service";
import { FiltersService } from '../services/filters.service';
import { CscService } from '../services/csc.service';
import { LoadingPopupComponent } from '../common/loading/loading.component';
import { ContributionListComponent } from '../search-result/contribution-list/contribution-list.component';
import { TagsPopupComponent } from '../common/tags-popup/tags-popup.component';
import { AuthService } from '../services/auth.service';

export interface PeriodicElement {
  part: string;
  category: string;
  duration: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {part: 'Cannon-Rumble Ramble 9 Shot	', category: '200g Cake / Cannon	', duration: '20 seconds'},
  {part: 'Cannon-Rumble Ramble 9 Shot	', category: '200g Cake / Cannon	', duration: '20 seconds'},
  {part: 'Cannon-Rumble Ramble 9 Shot	', category: '200g Cake / Cannon	', duration: '20 seconds'},
];

@Component({
  selector: 'app-single-page',
  templateUrl: './single-page.component.html',
  styleUrls: ['./single-page.component.scss']
})
export class SinglePageComponent implements OnInit, AfterViewInit, OnDestroy {

  displayedColumns: string[] = ['created_at', 'name', 'field_name'];
  dataSource: ContributionsDataSource;
  filters:any;
  defaults:any;
  firstLoad = false;
  firework: any;
  loading = true;
  videoEmbedURL:any;
  filterLoaded = false;
  subscriptions:any = [];
  user: any;

  showAddToFireworksButton = true;
  fireworkIds: any = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private route: ActivatedRoute,
              private solrService: SolrService,
              private contributionService: ContributionService,
              private location: Location,
              private dialog: MatDialog,
              private router: Router,
              private sanitizer: DomSanitizer,
              private filtersService: FiltersService,
              private cscService: CscService,
              private authService: AuthService,) {
    this.dataSource = new ContributionsDataSource(contributionService);
    this.subscriptions.push(this.filtersService.filters.subscribe((filters:any) => {
      this.filters = filters;
    }))
    this.subscriptions.push(this.authService.userSubject.subscribe((user) => {
      this.user = user
    }));
    this.subscriptions.push(this.filtersService.filters.subscribe((filters:any) => {
      this.filters = filters;
      if(this.filterLoaded){
        let pageData = this.filtersService.parseFilters();
        this.router.navigate(
          ['/'],
          { queryParams: pageData.queryParams }
        );
      }
      else{
        this.filterLoaded = true;
      }
    }))
    this.subscriptions.push(this.solrService.defaults.subscribe((defaults:any) => {
      this.defaults = defaults;
    }));
    this.subscriptions.push(this.cscService.showAddToFireworksButton.subscribe((status: boolean) => {
      this.showAddToFireworksButton = status;
      if(this.showAddToFireworksButton){
        this.cscService.addedFireworkIds.subscribe((fireworkIds: any) => {
          this.fireworkIds = fireworkIds;
        });
      }
    }));
  }

  addToFireworkIds(firework: any){
    const dialogRef = this.dialog.open(LoadingPopupComponent,{
      id: 'loading-box',
      data: {
        message: 'Adding Fireworks to your collection...'
      },
    });
    this.cscService.addToMyFirework(firework.id, this.user ? this.user.id : null).subscribe((response: any) => {
      this.cscService.addAlreadyAddedFireworkIds(firework.id);
      dialogRef.componentInstance.loadingDone.emit({
        type: 'success',
        title: "Fireworks Added",
        message: 'Firework has been added to your collection successfully.'
      });
    }, (error: any) => {
      dialogRef.componentInstance.loadingDone.emit({
        type: 'error',
        title: "Error",
        message: 'There is an error adding the firework to your collection. Try again or contact our support.'
      });
    });
  }

  openContribution(firework: any) {
    const dialogRef = this.dialog.open(ContributionListComponent,{
      id: 'contribution-area',
      data: {
        firework: firework
      },
    });
  }

  openTag(firework: any) {
    const dialogRef = this.dialog.open(TagsPopupComponent,
      {
        id: 'tag-area',
        panelClass: 'model-dialog-area',
        maxWidth: '500px',
        width: '100%',
        data: {
          firework: firework
        },
    });
  }

  invertColor(hex:any) {
    if(!hex) return '#000000';
    hex = hex.hex ?? hex;
  	if (hex.indexOf('#') === 0) {
			hex = hex.slice(1);
		}
		// convert 3-digit hex to 6-digits.
		if (hex.length === 3) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}
		if (hex.length !== 6) {
			throw new Error('Invalid HEX color.');
		}
		let r = parseInt(hex.slice(0, 2), 16),
			g = parseInt(hex.slice(2, 4), 16),
			b = parseInt(hex.slice(4, 6), 16);
		return (r * 0.299 + g * 0.587 + b * 0.114) > 186
			? '#000000'
			: '#FFFFFF';
	}

  ngOnInit(): void {
    
  }

  ngOnDestroy() {
    for(let subscription of this.subscriptions){
      subscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    // merge(this.sort.sortChange, this.paginator.page)
    //   .pipe(
    //     tap(() => {
    //       this.loadContribution();
    //     })
    //   )
    //   .subscribe();
    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.route.paramMap.subscribe((paramMap:any) => {
      if(paramMap.params.brand && paramMap.params.firework){
        this.solrService.show(paramMap.params.brand, paramMap.params.firework).subscribe((firework: any) => {
          this.firework = firework;
          if(this.firework.video.indexOf('youtu.be') !== -1){
            this.videoEmbedURL = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.firework.video.substring(this.firework.video.lastIndexOf('/')+1) + "/?autoplay=1")
          }
          else if(this.firework.video){
            this.videoEmbedURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.firework.video)
          }
          //this.loadContribution();
          this.loading = false;
          if(Object.keys(this.defaults).length === 0){
            this.firstLoad = true;
            this.solrService.getDefaults();
          }
        }, error => {
          this.router.navigate(['/']);
        });
      }
    });
  }

  // loadContribution() {
  //   this.dataSource.list(
  //     {firework_id: this.firework.id},
  //     this.sort.active,
  //     this.sort.direction,
  //     this.paginator.pageIndex + 1,
  //     this.paginator.pageSize);
  // }
  back(){
    if(this.firstLoad){
      this.router.navigate(['']);
    }
    else{
      this.location.back();
    }
  }

  image_popup(item: any, name = null){
    const dialogRef = this.dialog.open(ImagePopupComponent, {
      id: 'image-popup',
      data: {
        image: name ? item : item.image,
        title: name ? name : item.name
      },
      maxHeight: '95vh'
    })
  }

  video_popup(item: any, name = null){
    const dialogRef = this.dialog.open(VideoPopupComponent, {
      id: 'video-popup',
      data: {
        video: name ? item : item.video,
        title: name ? name : item.name
      },
    })
  }

}
