import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-color-picker-popup',
  templateUrl: './color-picker-popup.component.html',
  styleUrls: ['./color-picker-popup.component.scss']
})
export class ColorPickerPopupComponent implements OnInit {

  public color: ThemePalette = 'primary';
  colorCtr = new FormControl();
  constructor() { }
  
  ngOnInit(): void {
  }
  save(){}
}
