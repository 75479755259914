import { Component, OnInit, SimpleChanges, ElementRef } from '@angular/core';
import { ResizeService } from '../../services/resize.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  inputs: ['width', 'height', 'key'],
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  cloudFrontUrl = 'https://dmzxu9j2sl61z.cloudfront.net';
  url = '';
  width:  string|null = null;
  height:  string|null = null;
  containerheight = '';
  resizeSubscription: any;
  key:  string|null = null;

  constructor(private el:ElementRef,
    private resizeService: ResizeService) {
    
  }

  ngOnChanges( changes: SimpleChanges ){
    this.generateUrl();
  }

  ngOnInit(): void {
    this.resizeSubscription = this.resizeService.onResize$
      .subscribe((size: any) => {
        if(this.el.nativeElement.parentElement.offsetWidth){
          this.width = this.el.nativeElement.parentElement.offsetWidth;
          if(this.containerheight){
            this.height = this.el.nativeElement.parentElement.offsetHeight;
          }
          this.generateUrl();
        }
      });
  }

  ngAfterViewInit(){
    setTimeout(() => {
      if(this.el.nativeElement.parentElement.offsetWidth){
        this.width = this.el.nativeElement.parentElement.offsetWidth;
      }
      else if(!this.width){
        this.width = "120";
      }
      if(this.containerheight){
        this.height = this.el.nativeElement.parentElement.offsetHeight;
      }
      this.generateUrl();
    }, 200);
  }

  generateUrl(): void{
    let resize:any = {
      width: this.width
    }
    if(this.height){
      resize.height = this.height
    }
    if(this.key){
      this.key = this.key.replace('https://ignite-bucket-s3.s3.amazonaws.com/', '');
    }
    const imageRequest = JSON.stringify({
      bucket: "ignite-bucket-s3",
      key: this.key,
      edits: {
        resize: resize
      }
    });
    this.url = `${this.cloudFrontUrl}/${btoa(imageRequest)}`;
  }

}
