<mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>
<div class="single-firework" [hidden]="loading">
    <div class="row">
        <div class="col-md-12" style="margin-bottom: 20px;">
          <button class="back-btn" mat-raised-button (click)="back()">
            <mat-icon>arrow_back_ios</mat-icon>
            Back
          </button>
        </div>
        
        <div class="col-md-7">
            <div *ngIf="firework">
              <div class="firework-page-head">
                  <div class="firework-head-img">
                    <app-image *ngIf="firework.brand_logo" [key]="firework.brand_logo" width="70"></app-image>
                    <mat-icon *ngIf="!firework.brand_logo">broken_image</mat-icon>
                  </div>
                  <div class="firework-head-title">
                      <h3>{{ firework.name }}</h3>
                      <div class="tags csc-firework" *ngIf="firework">
                        <a (click)="addToFireworkIds(firework)" *ngIf="showAddToFireworksButton && fireworkIds.indexOf(firework.id)===-1">Add to My Fireworks</a>
                        <a href="javascirpt:void()" *ngIf="showAddToFireworksButton && fireworkIds.indexOf(firework.id)!==-1" class="disabled">Added to My Fireworks</a>
                        <a (click)="openTag(firework)">Tag</a>
                        <a (click)="openContribution(firework)">Contribute</a>
                        <mat-chip-listbox aria-label="Firework Tags" *ngIf="user">
                          <ng-container *ngFor="let tag of user.tags">
                              <mat-chip [style.background-color]="tag.color ?? '#ffffff'" *ngIf="tag.fireworks.indexOf(firework.id) !== -1" (click)="openTag(firework)">
                                  <span [style.color]="invertColor(tag.color)">{{ tag.name }}</span>
                              </mat-chip>
                          </ng-container>
                        </mat-chip-listbox>
                      </div>
                  </div>
              </div>
              <div class="table">
                <table class="w-100">
                  <tr>
                    <th>Part #</th>
                    <th>Category</th>
                    <th>Duration</th>
                  </tr>
                  <tr>
                    <td>{{ firework.part_number }}</td>
                    <td>{{ firework.firework_type }}</td>
                    <td>{{ firework.duration }}</td>
                  </tr>
                </table>
              </div>
              <p [innerHTML]="firework.description"></p>
            </div>
            <div class="table" *ngIf="firework">
              <h3>Recent Contributions</h3>
              <table class="w-100" mat-table [dataSource]="firework.contributions">
      
                <ng-container matColumnDef="created_at">
                  <th width="20%" mat-header-cell *matHeaderCellDef> Date </th>
                  <td mat-cell *matCellDef="let element"> {{element.created_at | date : 'mediumDate'}} </td>
                </ng-container>
              
                <ng-container matColumnDef="name">
                  <th width="20%" mat-header-cell *matHeaderCellDef> User </th>
                  <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>
              
                <ng-container matColumnDef="field_name">
                  <th  mat-header-cell *matHeaderCellDef> Contribution </th>
                  <td mat-cell *matCellDef="let element">
                      <b *ngIf="element.field_name == 'duration'">Duration: {{ element.field_value }}</b>
                      <b *ngIf="element.field_name == 'description'">Comments / Notes: {{ element.field_value }}</b>
                      <b *ngIf="element.field_name == 'name'">Name: {{ element.field_value }}</b>
                      <b *ngIf="element.field_name == 'image'"><a (click)="image_popup(element.field_value, firework.name)">
                              <app-image *ngIf="element.field_value" width="120" [key]="element.field_value"></app-image>
                            </a></b>
                      <b *ngIf="element.field_name == 'video'"><a (click)="video_popup(element.field_value, firework.name)" class="youtube-icon"><mat-icon>play_circle_filled</mat-icon></a></b>
                  </td>
                </ng-container>
              
              
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="3">No contributions added to this firework.</td>
                  </tr>
              </table>
            </div>
        </div>
        <div class="col-md-5" *ngIf="firework">
            <div class="product-img">
              <a (click)="image_popup(firework)" *ngIf="firework.image" style="display: block">
                <app-image [key]="firework.image"></app-image>
              </a>
            </div>
            <div class="product-video" *ngIf="videoEmbedURL">
                <iframe allowfullscreen="allowfullscreen" allow="autoplay; fullscreen" [src]="videoEmbedURL" scrolling="no"></iframe>
            </div>
        </div>
    </div>
</div>
