<div *ngIf="page">
    <div [innerHTML]="page?.content"></div>
</div>
<h2>Recent Contributions</h2>
<div class="table" *ngIf="contributions">
    <table class="w-100" mat-table [dataSource]="contributions">

        <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
        
        <ng-container matColumnDef="created_at">
            <th width="10%" mat-header-cell *matHeaderCellDef> Date</th>
            <td mat-cell *matCellDef="let element"> {{element.created_at | date : 'mediumDate'}} </td>
        </ng-container>
        
        <ng-container matColumnDef="user">
            <th width="15%" mat-header-cell *matHeaderCellDef> User </th>
            <td mat-cell *matCellDef="let element"> {{element.user.name}} </td>
        </ng-container>
        
        <ng-container matColumnDef="firework">
            <th width="25%" mat-header-cell *matHeaderCellDef> Firework Name </th>
            <td mat-cell *matCellDef="let element"> <a [routerLink]="['/firework/' + element.firework.brand.slug + '/' + element.firework.slug]">{{element.firework.name}}</a></td>
        </ng-container>

        <ng-container matColumnDef="contributions">
            <th mat-header-cell *matHeaderCellDef> Contributions</th>
            <td mat-cell *matCellDef="let element">
                <b *ngIf="element.field_name == 'duration'">Duration: {{ element.field_value }}</b>
                <b *ngIf="element.field_name == 'description'">Comments / Notes: {{ element.field_value }}</b>
                <b *ngIf="element.field_name == 'name'">Name: {{ element.field_value }}</b>
                <b *ngIf="element.field_name == 'image'"><a (click)="image_popup(element)">
                        <app-image *ngIf="element.field_value" width="120" [key]="element.field_value"></app-image>
                      </a></b>
                <b *ngIf="element.field_name == 'video'"><a (click)="video_popup(element)" class="youtube-icon"><mat-icon>play_circle_filled</mat-icon></a></b>
            </td>
            </ng-container>
        
        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
<h2 *ngIf="fireworks && fireworks.length > 0">Recent Fireworks added by public users</h2>
<div class="table" *ngIf="fireworks && fireworks.length > 0">
    <table mat-table [dataSource]="fireworks" class="w-100" matSort>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td class="column-primary" mat-cell *matCellDef="let element"><a [routerLink]="['/firework/' + element.brand_slug + '/' + element.slug]">{{element.name}}</a></td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="video">
        <th style="width:10%;" mat-header-cell *matHeaderCellDef> Video </th>
        <td data-colname="Video" style="text-align:center;" mat-cell *matCellDef="let element">
          <a (click)="video_popup(element, true)" class="youtube-icon" *ngIf="element.video_url"><mat-icon>play_circle_filled</mat-icon></a>
          <span *ngIf="!element.video_url">No Video</span>
        </td>
      </ng-container>
  
      <!-- Name Column -->
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef> Image </th>
        <td data-colname="Image" mat-cell *matCellDef="let element">
          <a (click)="image_popup(element, true)" *ngIf="element.image_url">
            <app-image width="120" [key]="element.image_url"></app-image>
          </a>
          
          <span *ngIf="!element.image_url">No Image</span>
        </td>
      </ng-container>
  
      <!-- Brand Column -->
      <ng-container matColumnDef="brand">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Brand </th>
        <td data-colname="Brand" mat-cell *matCellDef="let element"> {{ element.brand.name ?? '--'}} </td>
      </ng-container>
  
      <!-- Brand Column -->
      <ng-container matColumnDef="firework_type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
        <td data-colname="Type" mat-cell *matCellDef="let element"> {{ element.firework_type.name ?? '--'}} </td>
      </ng-container>
  
      <!-- Brand Column -->
      <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Duration </th>
        <td data-colname="Duration" mat-cell *matCellDef="let element"> {{ element.duration ? element.duration + ' secs' : 'No Duration'}} 
          </td>
      </ng-container>
  
      <ng-container matColumnDef="part_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Part Number </th>
        <td data-colname="Part Number" mat-cell *matCellDef="let element"> {{element.part_number}} </td>
      </ng-container>
  
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
        <td data-colname="Description" mat-cell *matCellDef="let element"> {{element.description}}
          
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumnsFireworks"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsFireworks;"></tr>
  
      <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell no-results-cell" colspan="9">No data found matching the selected filters.</td>
      </tr>
    </table>
  </div>
