<h2 class="popup-heading {{ type }}" mat-dialog-title *ngIf="title">{{ title }}</h2>
<mat-dialog-content class="mat-typography">
    <div class="brands-view">
        <p><mat-icon style="display: block; float:left" *ngIf="processing">
            <mat-spinner color="primary" diameter="20"></mat-spinner>
          </mat-icon> {{ message }}</p>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!processing">
  <button class="popup-save" mat-button (click)="close()" cdkFocusInitial>
    Close
  </button>
</mat-dialog-actions>
