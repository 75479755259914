import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data.service';
import { ContributionService } from '../services/contribution.service';
import { ImagePopupComponent } from '../common/image-popup/image-popup.component';
import { VideoPopupComponent } from '../common/video-popup/video-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {
  page:any;
  settings: any;
  contributions: any;
  fireworks: any;
  path = '';
  displayedColumns: string[] = ['created_at', 'user', 'firework', 'contributions'];
  displayedColumnsFireworks: string[] = ['name', 'video', 'image', 'brand', 'firework_type', 'duration', 'part_number', 'description'];;
  dataSource: any;
  constructor(private route: ActivatedRoute,
    private dataService: DataService,
    private contributionService: ContributionService,
    public dialog: MatDialog,
    private titleService: Title) {
    this.dataService.settings.subscribe((settings: any) => {
      if(settings.pages){
        this.settings = settings;
        this.findPage();
      }
    });
  }

  ngOnInit(): void {
    this.route.url.subscribe((url: any) => {
      this.path = '';
      if(url.length > 0){
        for(let part of url){
          this.path += '/' + part.path.replace('.html', '')
        }
        this.path = this.path.slice(1);
        this.page = null;
        this.contributions = null;
        this.findPage();
      }
    });
  }

  findPage(){
    if(this.settings && this.path){
      this.settings.pages.menu.some((menu: any) => {
        menu.children.some((page: any) => {
          console.log(this.path+' === '+page.slug)
          if(this.path === page.slug){
            this.page = page;
            this.titleService.setTitle(page.title);
            if(this.page.slug === 'contributions'){
              this.getContributionPageData();
            }
            return true;
          }
          return false;
        });
        console.log(this.page);
        return this.page ? true : false;
      });
    }
  }

  getContributionPageData(){
    this.contributionService.pageData().subscribe((data: any) => {
        this.contributions = data.contributions;
        this.fireworks = data.fireworks;
      });
  }

  image_popup(item: any, isFirework = false){
    const dialogRef = this.dialog.open(ImagePopupComponent, {
      id: 'image-popup',
      data: {
        image: isFirework ? item.image_url : item.field_value,
        title: isFirework ? item.name : item.firework.name
      },
      maxHeight: '95vh'
    })
  }

  video_popup(item: any, isFirework = false){
    const dialogRef = this.dialog.open(VideoPopupComponent, {
      id: 'video-popup',
      data: {
        video: isFirework ? item.video_url : item.field_value,
        title: isFirework ? item.name : item.firework.name
      },
    })
  }

}
