import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  filters = new BehaviorSubject<any>({});
  filtersLoaded = new BehaviorSubject<any>(false);
  defaultSavedSearch = {
    duration: {
      min: 0,
      max: 60
    },
    countries: [],
    regulations: [],
    firework_types: [],
    brands: [],
    search: '',
    is_name: false,
    has_video: false,
    has_duration: false,
    tagged_only: false,
    tags: [],
    ids: [],
    orderBy: 'score',
    order: 'desc',
    page: 1,
    per_page: 25,
    expanded: {
      duration: true,
      countries: true,
      regulations: true,
      firework_types: true,
      brands: true,
    }
  };
  constructor() {
    if(localStorage.getItem("filters") && 0){
      this.filters.next(JSON.parse(<string>localStorage.getItem("filters")));
    }
    else{
      this.filters.next(Object.assign({}, this.defaultSavedSearch));
    }
  }

  setFilters(filters: any = null){
    // console.log(this.filters.value);
    // console.log(filters);
    // if(JSON.stringify(this.filters.value) === JSON.stringify(filters)){
    //   console.log("w");
    // }
    if(!filters){
      filters = Object.assign({}, this.defaultSavedSearch);
    }
    if(!filters.expanded){
      filters.expanded = {
        duration: true,
        countries: true,
        regulations: true,
        firework_types: true,
        brands: true,
      };
    }
    this.filters.next(filters);
    localStorage.setItem("filters", JSON.stringify(filters));
  }
  setFiltersLoaded(status: any){
    this.filtersLoaded.next(status);
  }
  parseFilters(){
    let filters = this.filters.getValue();
    let queryParams:any = {};
    let pageTitle = '';
    if(filters.brands.length > 0){
      queryParams.brands = filters.brands.toString();
      pageTitle += 'Brands: ' + queryParams.brands + " ";
    }
    if(filters.countries.length > 0){
      queryParams.countries = filters.countries.toString();
      pageTitle += 'Countries: ' + queryParams.countries + " ";
    }
    if(filters.regulations.length > 0){
      queryParams.regulations = filters.regulations.toString().replace('0', 'Consumer (UN0336)').replace('1', 'Professional (UN0431)');
      pageTitle += 'Regulations: ' + queryParams.regulations + " ";
    }
    if(filters.firework_types.length > 0){
      queryParams.types = filters.firework_types.toString();
      pageTitle += 'Types: ' + queryParams.types + " ";
    }
    if(filters.duration.min > 0){
      queryParams.min_duration = filters.duration.min;
      pageTitle += 'Min. Duration: ' + queryParams.min_duration + ' secs ';
    }
    if(filters.duration.max < 60){
      queryParams.max_duration = filters.duration.max;
      pageTitle += 'Max. Duration: ' + queryParams.max_duration + ' secs ';
    }
    if(filters.has_video){
      queryParams.has_video = filters.has_video;
      pageTitle += 'having Videos ';
    }
    if(filters.has_duration){
      queryParams.has_duration = filters.has_duration;
      pageTitle += 'having duration ';
    }
    if(filters.search){
      if(filters.is_name){
        queryParams.name = filters.search;
      }
      else{
        queryParams.s = filters.search;
      }
      pageTitle += 'search: ' + filters.search + ' ';
    }
    return {
      queryParams: queryParams,
      pageTitle: pageTitle
    }
  }
}
