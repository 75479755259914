import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { ImagePopupComponent } from 'src/app/common/image-popup/image-popup.component';
import { VideoPopupComponent } from 'src/app/common/video-popup/video-popup.component';
import { ContributionService } from 'src/app/services/contribution.service';
import { MatTable } from '@angular/material/table';

export interface PeriodicElement {
  part: string;
  category: string;
  duration: string;
}


@Component({
  selector: 'app-contribution-list',
  templateUrl: './contribution-list.component.html',
  styleUrls: ['./contribution-list.component.scss']
})
export class ContributionListComponent implements OnInit {

  displayedColumns: string[] = ['created_at', 'name', 'field_name'];
  firework: any;
  error: any;
  processing = false;
  video: any;
  image: any;
  duration: any;
  description: any;
  user: any;
  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild('imageFile') imageFile!: ElementRef
  constructor(public dialog: MatDialog,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) data:any,
    private dialogRef: MatDialogRef<ContributionListComponent>,
    private contributionService: ContributionService,
    private changeDetectorRefs: ChangeDetectorRef) {
      this.firework = data.firework;
      this.authService.userSubject.subscribe((user: any) => {
        this.user = user
      });
    }

  ngOnInit(): void {
  }
  saveVideo(){
    this.error = null;
    if(this.video && (this.video.indexOf('https://youtu.be/') === 0 || this.video.indexOf('https://www.youtube.com/') === 0)){
      this.saveContribution('video', this.video)
    }
    else{
      this.error = "Please enter valid youtube video url.";
    }
  }
  fileSelect(event: any){
    const file:File = event.target.files[0];

		if (file) {
      this.image = file;
    }
  }
  saveImage(){
    this.error = null;
    if(this.image){
      this.saveContribution('image', this.image)
    }
    else{
      this.error = "Please provide valid image file.";
    }
  }
  saveDuration(){
    this.error = null;
    if(this.duration){
      this.saveContribution('duration', this.duration)
    }
    else{
      this.error = "Please enter duration greater than 0.";
    }
  }
  saveComments(){
    this.error = null;
    if(this.description){
      this.saveContribution('description', this.description)
    }
    else{
      this.error = "Please enter some comments / notes.";
    }
  }
  saveContribution(field: any, value: any){
    this.processing = true;
    this.contributionService.saveContribution(this.firework.id, field, value).subscribe((response: any) => {
      if(response.status){
        this.firework.contributions.unshift(response.contribution);
        this.table.renderRows();
      }
      else{
        this.error = response.error;
      }
      this.video = null;
      this.image = null;
      this.imageFile.nativeElement.value = "";
      this.description = null;
      this.duration = null;
      this.processing = false;
    });
  }
  image_popup(item: any){
    const dialogRef = this.dialog.open(ImagePopupComponent, {
      id: 'image-popup',
      data: {
        image: item.field_value,
        title: this.firework.name
      },
      maxHeight: '95vh'
    })
  }

  video_popup(item: any){
    const dialogRef = this.dialog.open(VideoPopupComponent, {
      id: 'video-popup',
      data: {
        video: item.field_value,
        title: this.firework.name
      },
    })
  }

}
