import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NewSavedSearchComponent } from './new-saved-search/new-saved-search.component';
import { MatDialog } from '@angular/material/dialog';
import { FiltersService } from 'src/app/services/filters.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-saved-search-form',
  templateUrl: './saved-search-form.component.html',
  styleUrls: ['./saved-search-form.component.scss']
})
export class SavedSearchFormComponent implements OnInit {
  user: any;
  loaded = false;
  savedSearch: any;
  filters:any;
  saving = false;
  deleting = false;
  constructor(public authService: AuthService,
    public filterService: FiltersService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar) {
    this.authService.user.subscribe((user: any) => {
      if(user){
        this.loaded = true;
        this.user = user;
        if(Number(localStorage.getItem("savedSearch")) > -1 && this.user.saved_searches[Number(localStorage.getItem("savedSearch"))]){
          this.savedSearch = this.user.saved_searches[Number(localStorage.getItem("savedSearch"))]
        }
      }
    })
    this.filterService.filters.subscribe((filters: any) => {
      this.filters = filters;
    });
  }

  ngOnInit(): void {
  }

  loadSavedSearch(){
    if(this.savedSearch){
      this.filterService.setFilters(this.savedSearch.session);
      localStorage.setItem("savedSearch", this.user.saved_searches.indexOf(this.savedSearch));
    }
    else{
      this.filterService.setFilters(null);
      localStorage.setItem("savedSearch", "-1");
    }
  }
  newSavedSearch(){
    const dialogRef = this.dialog.open(NewSavedSearchComponent, {
      id: 'new-saved-search',
      maxHeight: '95vh'
    });
    dialogRef.componentInstance.onConfirm.subscribe((name: any) => {
      this.user.saved_searches.push({
        name: name,
        session: this.filters
      });
      this.authService.saveSavedSearch(this.user.saved_searches).subscribe((response: any) => {
        if(response.status){
          this._snackBar.open("New Saved search is created successfully.", 'Dismiss');
          this.savedSearch = this.user.saved_searches.slice(-1).pop();
          this.loadSavedSearch();
          dialogRef.close();
        }
      });
    });
  }
  saveSavedSearch(){
    //let index = this.user.saved_searches.indexOf(this.savedSearch);
    this.savedSearch.session = this.filters;
    this.saving = true;
    this.authService.saveSavedSearch(this.user.saved_searches).subscribe((response: any) => {
      this.saving = false;
      if(response.status){
        this._snackBar.open("Saved search is updated successfully.", 'Dismiss');
      }
    });
  }
  deleteSavedSearch(){
    let index = this.user.saved_searches.indexOf(this.savedSearch);
    this.user.saved_searches.splice(index, 1);
    this.deleting = true;
    this.authService.saveSavedSearch(this.user.saved_searches).subscribe((response: any) => {
      this.deleting = false;
      if(response.status){
        this.savedSearch = null;
        this.loadSavedSearch();
        this._snackBar.open("Saved search is deleted successfully.", 'Dismiss');
        this.savedSearch = this.user.saved_searches.slice(-1).pop();
      }
    });
  }
}
