import { Injectable } from '@angular/core';
import {
	HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import {Observable} from 'rxjs';
import {Router} from "@angular/router";
import {tap} from "rxjs/operators";

/** Pass untouched request through to the next request handler. */
@Injectable()
export class OptionsInterceptor implements HttpInterceptor {
	constructor(private router: Router) {}
	intercept(req: HttpRequest<any>, next: HttpHandler):
		Observable<HttpEvent<any>> {
		let laravelReq
		if(req.url.indexOf('https://api.cobrashowcreator.com/')===-1 && req.url.indexOf('https://api.ignitefiringsystems.com/')===-1){
			laravelReq = req.clone(
				{
					/*headers: new HttpHeaders({
						//Accept: 'application/json',
						//'Content-Type': 'application/json'
					}),*/
					withCredentials: true,
				}
			);
		}
		else{
			laravelReq = req.clone(
				{
				}
			);
		}
		return next.handle(laravelReq).pipe(
			tap(
				error => this.handleResponse(req, error),
				error => this.handleError(req, error)
			)
		);
	}
	handleResponse(req: HttpRequest<any>, event: any) {

	}
	handleError(req: HttpRequest<any>, event: any) {
		if(event.status == 401){
			//this.router.navigate(['/login']);
		}
	}
}
