import { Component, Inject, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-new-saved-search',
  templateUrl: './new-saved-search.component.html',
  styleUrls: ['./new-saved-search.component.scss']
})
export class NewSavedSearchComponent {
  onConfirm = new EventEmitter();
  public processing;
  public name: any;
	responseError = new EventEmitter();
  errors:any;

  constructor(private dialogRef: MatDialogRef<NewSavedSearchComponent>,
							@Inject(MAT_DIALOG_DATA) data:any ) {
    this.processing = false;
  }

  ngOnInit(): void {
    this.responseError.subscribe(( result: any ) => {
        this.errors = [result];
        this.processing = false;
    });
  }

  ngOnChange(): void {
  }

	cancel() {
		this.dialogRef.close(false);
	}

  confirm() {
    if(this.name){
      this.processing = true;
      this.onConfirm.emit(this.name);
    }
  }
}
