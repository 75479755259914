<h2 mat-dialog-title>Contribution to this firework</h2>
<mat-dialog-content class="mat-typography">
  <mat-tab-group mat-align-tabs="start" *ngIf="user">
    <mat-tab label="YouTube Video">
      <div class="serach-box">
        <input type="text" class="form-control" [(ngModel)]="video" placeholder="Enter new YouTube video URL (e.g. https://youtu.be/ZyjKbx36k90)" >
        <input type="button" value="Submit" (click)="saveVideo()" [disabled]="processing">
      </div>
    </mat-tab>
    <mat-tab label="Image">
      <div class="serach-box">
        <input type="file" class="form-control" placeholder="" (change)="fileSelect($event)" #imageFile>
        <input type="button" value="Submit" [disabled]="processing" (click)="saveImage()" [disabled]="processing">
      </div>
    </mat-tab>
    <mat-tab label="Duration">
      <div class="serach-box">
        <input type="number" class="form-control" [(ngModel)]="duration" placeholder="Enter Duration in seconds" >
        <input type="button" value="Submit" (click)="saveDuration()" [disabled]="processing">
      </div>
    </mat-tab>
    <mat-tab label="Comments / Notes">
      <div class="serach-box">
        <textarea placeholder="Enter new description / notes..." [(ngModel)]="description"></textarea>
        <input type="button" value="Submit" (click)="saveComments()" [disabled]="processing">
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="errors" *ngIf="error">
    <p>{{ error }}</p>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="processing"></mat-progress-bar>
  <div class="table">
    <table class="w-100" mat-table [dataSource]="firework.contributions">
      
        <ng-container matColumnDef="created_at">
          <th width="20%" mat-header-cell *matHeaderCellDef> Date </th>
          <td mat-cell *matCellDef="let element"> {{element.created_at | date : 'mediumDate'}} </td>
        </ng-container>
      
        <ng-container matColumnDef="name">
          <th width="20%" mat-header-cell *matHeaderCellDef> User </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
      
        <ng-container matColumnDef="field_name">
          <th  mat-header-cell *matHeaderCellDef> Contribution </th>
          <td mat-cell *matCellDef="let element">
              <b *ngIf="element.field_name == 'duration'">Duration: {{ element.field_value }}</b>
              <b *ngIf="element.field_name == 'description'">Comments / Notes: {{ element.field_value }}</b>
              <b *ngIf="element.field_name == 'name'">Name: {{ element.field_value }}</b>
              <b *ngIf="element.field_name == 'image'"><a (click)="image_popup(element)">
                      <app-image *ngIf="element.field_value" width="120" [key]="element.field_value"></app-image>
                    </a></b>
              <b *ngIf="element.field_name == 'video'"><a (click)="video_popup(element)" class="youtube-icon"><mat-icon>play_circle_filled</mat-icon></a></b>
          </td>
        </ng-container>
      
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="3">No contributions added to this firework.</td>
          </tr>
      </table>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Close</button>
</mat-dialog-actions>