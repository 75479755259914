<div class="add-new-firework">
    <h4>Add New Firework</h4>
    <form class="main-error" (ngSubmit)="submitForm($event)" [formGroup]="form">
        <div class="errors" *ngIf="errors.length > 0">
			<span *ngFor="let error of errors | keyvalue">{{ error.value }}</span>
		</div>
        <div class="field">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Name</mat-label>
                <input [formControl]="form.controls.name" matInput placeholder="Name">
                <mat-error  *ngIf="form.controls.name.invalid">Name field is required</mat-error>
                <!-- <mat-error [ngStyle]="{'display': (form.controls.name.invalid ? 'block !important' : 'none !important')}">Name field is required</mat-error> -->
            </mat-form-field>
        </div>
        <div class="field">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Description</mat-label>
                <textarea [formControl]="form.controls.description" matInput></textarea>
            </mat-form-field>
        </div>
        <div class="field brand_select " [ngClass]="{' brand_select_error ': (form.controls.brand_id.touched && form.controls.brand_id.invalid) || (isSubmit && form.controls.brand_id.invalid)}">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Brand</mat-label>
                <mat-select [formControl]="form.controls.brand_id">
                    <mat-option *ngFor="let brand of brands" [value]="brand.id">{{ brand.name }}</mat-option>
                </mat-select>
                <mat-error class="error-i" *ngIf="form.controls.brand_id.invalid">Brand field is required</mat-error>
            </mat-form-field>
            <p>If you don't see a brand, please email us at <a href="mailto:help@ignitefiringsystems.com"> help@ignitefiringsystems.com </a> and we'll email you back quickly.</p>
        </div>
        <div class="field">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Category</mat-label>
                <mat-select [formControl]="form.controls.firework_type_id">
                    <mat-option *ngFor="let fireworkType of fireworkTypes" [value]="fireworkType.id">{{ fireworkType.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="form.controls.firework_type_id.invalid">Category field is required</mat-error>
            </mat-form-field>
        </div>
        <div class="field">
            <mat-form-field class="w-100" appearance="outline">
                <mat-label>Part Number</mat-label>
                <input [formControl]="form.controls.part_number" matInput placeholder="Part Number">
            </mat-form-field>
        </div>
        <div class="row">
            <!-- <div class="col-md-6">
                <div class="field">
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>QR code</mat-label>
                        <input [formControl]="form.controls.qrcode" matInput placeholder="QR code">
                        <mat-error *ngIf="form.controls.qrcode.invalid">QR code field is required</mat-error>
                    </mat-form-field>
                </div>
            </div> -->
            <div class="col-md-12">
                <div class="field">
                    <mat-form-field class="w-100 barcode" appearance="outline">
                        <mat-label>Barcode</mat-label>
                        <input [formControl]="form.controls.barcode1" matInput placeholder="Barcode">
                    </mat-form-field>
                    <p>Make sure to enter the full barcode numbers as displayed on the product. This will help others scan the items quickly</p>
                </div>
            </div>
        </div>
        <div class="field serach-box">
            <input [formControl]="form.controls.duration" type="number" class="form-control" placeholder="Enter Duration in seconds" >
            <!-- <input type="button" value="Submit"> -->
        </div>
        <div class="image-upload">
            <div class="upload-btn-wrapper">
                <button class="btn">{{ image ? 'Change' : 'Upload' }} an Image</button>
                <input type="file" name="myfile" (change)="imageRead($event)"/>
                <span>{{ imageName }}</span>
            </div>
        </div>
        <!-- <div class="field serach-box">
            <input type="file" class="form-control" placeholder="Image" #imageFile (change)="imageRead($event)">
            <input type="button" value="Submit">
        </div> -->
        <div class="field serach-box">
            <input [formControl]="form.controls.video_url" type="text" class="form-control"placeholder="Enter new YouTube video URL (e.g. https://youtu.be/ZyjKbx36k90)" >
            <!-- <input type="button" value="Submit"> -->
        </div>
        <div class="field-btn">
            <button style="margin-left: 10px;" class="cancel-popup" mat-button (click)="cancel()">Cancel</button>
            <button class="save-btn" mat-button type="submit" [disabled]="processing">
                Save
                <mat-icon *ngIf="processing">
                    <mat-spinner color="accent" diameter="20"></mat-spinner>
                </mat-icon>
            </button>
        </div>
    </form>
</div>