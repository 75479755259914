<div class="login" *ngIf="processing">
  <div style="width: 80%">
    Please wait...
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>
<div class="login-page" *ngIf="!processing">
  <div class="row justify-content-center">
      <div class="col-md-8">
          <div class="card">
              <div class="card-header">Login</div>
              <div class="card-body">
                  <div class="social-icons">
                      <div class="row">
                          <div class="col-md-6 login-icon google-login">
                                <asl-google-signin-button type='standard' size='large' data-text="Login with Google"></asl-google-signin-button>
                          </div>
                          <div class="col-md-6 login-icon">
                           <a class="apple-icon" (click)="apple_login()">
                            <img src="/assets/img/apple-login-icon.png" />
                               <span>Login with Apple ID</span>
                           </a>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
