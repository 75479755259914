import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SolrService {

  constructor(private http:HttpClient) { }

  defaults = new BehaviorSubject<any>({});

  getDefaults(){
    this.http.get(environment.apiUrl + '/solr/query').subscribe((results:any) => {
      this.defaults.next(results.defaults)
    });
  }

  search(filters:any, orderBy:string, order:string,
         pageNumber:number, pageSize:number): Observable<any>{
    let params = new HttpParams();
    for(let key of ['countries', 'regulations', 'firework_types', 'brands']){
      for(let id of filters[key]){
        params = params.append(key + '[]', id);
      }
    }
    if(filters.duration.min > 0){
      params = params.append('duration[min]', filters.duration.min);
    }
    if(filters.duration.max < 60){
      params = params.append('duration[max]', filters.duration.max);
    }
    if(filters.has_video){
      params = params.append('has_video', 1);
    }
    if(filters.has_duration){
      params = params.append('has_duration', 1);
    }
    if(filters.search){
      params = params.append('search', filters.search);
      if(filters.is_name){
        params = params.append('name_search', true);
      }
    }
    if(filters.ids.length > 0){
      params = params.append('ids', JSON.stringify(filters.ids));
    }
    params = params.append('order_by', orderBy);
		params = params.append('order', order);
		params = params.append('page', pageNumber);
		params = params.append('per_page', pageSize);
    return this.http.get(environment.apiUrl + '/solr/query', {params: params})
  }

  buildHttpParams(params: any, data: any, currentPath: string) {
    Object.keys(data).forEach(key => {
      console.log(data[key]);
      if (data[key] instanceof Object) {
        this.buildHttpParams(params, data[key], `${currentPath}${key}.`);
      } else {
        params[`${currentPath}${key}`] = data[key];
      }
    });
  }

  getSuggestion(filters: any):  Observable<any> {
		let params = new HttpParams();
  	Object.keys(filters).forEach(function (key) {
			params = params.append(key, filters[key]);
		});
		return this.http.get(environment.apiUrl + '/solr/suggestion', {
      params: params
    });
  }

  show(brand_slug:string, firework_slug:string): Observable<any> {
    return this.http.get(environment.apiUrl + '/solr/show/' + brand_slug + "/" + firework_slug);
  }

  saveUsersFireworks(firework: any){
    let formData = new FormData();
    for(let key in firework){
			if(key === 'image'){
				if(firework[key] && firework[key].name){
					formData.append(key, firework[key], firework[key].name);
				}
			}
			else{
				formData.append(key, firework[key]);
			}
		}
		return this.http.post(environment.apiUrl + '/add-users-fireworks', formData);
  }
}
