import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {AuthService} from '../services/auth.service';
import {UntypedFormBuilder} from "@angular/forms";
import {Router} from "@angular/router";
import { SocialUser, SocialAuthService } from '@abacritt/angularx-social-login';
import {MatSnackBar} from "@angular/material/snack-bar";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  socialUser: SocialUser | undefined;
  processing = false;
  constructor(fb: UntypedFormBuilder,
              private router: Router,
              private authService: AuthService,
              private _snackBar: MatSnackBar,
              private socialAuthService: SocialAuthService) {
  }

  ngOnInit(): void {
    // this.socialAuthService.authState.subscribe((user: any) => {
    //   if(user && !this.processing){
    //     this.processing = true;
    //     this.authService.process_google_login(user).subscribe((response: any) => {
    //       this.processing = false;
    //       if(this.authService.login(response)){
    //         this.router.navigate(['']);
    //       }
    //     }, (error: any) => {
    //       this.authService.logout().subscribe(() => {
    //         this.processing = false;
    //       });
    //       this._snackBar.open('Error signing in with Google. Try again', 'Close');
    //     });
    //   }
    // });
  }

  apple_login(){
    this.authService.apple_login().then((data: any) => {
      console.log(data);
			if(data.authorization){
				this.processing = true;
				let params:any = {token: data.authorization.code, user_data: data.user, origin: window.location.hostname};
				this.authService.process_apple_login(params).subscribe(response => {
					if(this.authService.login(response)){
            this.router.navigate(['']);
          }
				});
			}
			else{
				this._snackBar.open('Error signing in with Apple. Try again', 'Close');
			}
		});
  }
  
  logout(): void {
    this.authService.logout();
  }
}
