import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FiltersService} from "../../services/filters.service";
import { SolrService } from 'src/app/services/solr.service';
import { map } from 'rxjs';
import { Firework } from 'src/app/interfaces/firework.interface';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-add-new-firework',
  templateUrl: './add-new-firework.component.html',
  styleUrls: ['./add-new-firework.component.scss']
})
export class AddNewFireworkComponent {

  form:any;
  errorMsg = '';
	processing = false;
  fireworkTypes:any;
  brands:any;
  image: any;
	video: any;
  errors: any = [];
  authUser: any;
  imageName:any = '';
  isSubmit;

  public filters:any;
  subscriptions: any = [];
  public defaults: any;
  page:any = {};
  
  constructor(
		private fb: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any,
		private dialogRef: MatDialogRef<AddNewFireworkComponent>,
    private filtersService: FiltersService, private solrService: SolrService,
    private authService: AuthService, private ref: ChangeDetectorRef,
    private _snackBar: MatSnackBar, private dataService: DataService,
	) {
    this.subscriptions.push(this.filtersService.filters.subscribe((filters:any) => {
      this.filters = filters;
    }));
    this.subscriptions.push(
      this.solrService.defaults.pipe(
        map((res: any) => res.firework_types),
        map((fireworkTypes: any[]) => fireworkTypes.map((item) => item.record))
      )
      .subscribe((recordObjects: any[]) => {
        this.fireworkTypes = recordObjects;
      })
    );
    this.dataService.settingsSubject.subscribe((res: any) => {
      this.brands = res.brands;
    });
    this.filtersService.setFiltersLoaded(false);

		this.form = this.fb.group(this.addForm());
		this.processing = false;
    this.isSubmit = false;
	}

	ngOnInit(): void {
    this.authService.user.subscribe(user => {
      this.authUser = user;
      console.log(user);
    });
  }

  addForm() {
		return {
			name: ['', Validators.required],
			description: [''],
      brand_id: ['', Validators.required],
			firework_type_id: ['', Validators.required],
      part_number: [''],
			// qrcode: [''],
      barcode1: [''],
      duration: [''],
      video_url: [''],
		};
	}

  imageRead(fileInputEvent: any) {
		this.image = fileInputEvent.target.files[0];
    this.imageName = fileInputEvent.target.files[0].name;
    this.ref.detectChanges();
	}

  submitForm(event: any) {
    this.isSubmit = true;
		if (!this.processing) {
			this.errors = [];
			event.preventDefault();
			if (!this.form.valid) return;

			this.processing = true;
			const firework = this.form.value as Firework;
			if (this.image) {
				firework.image = this.image;
			}
			this.solrService.saveUsersFireworks(firework)
				.subscribe((res: any) => {
          console.log(res);
					this.processing = false;
					this.dialogRef.close(res.status);
          this._snackBar.open('Firework added successfully.', 'Dismiss');
          setTimeout(() => {
            this.filters.search = (res.firework.name).toString();
            this.filtersService.setFilters(this.filters);
          }, 4000);
				}, (response: any) => {
					this.processing = false;
					for (const property in response.error.errors) {
						const error = response.error.errors[property].join(", ");
						this.errors.push(error);
						this.form.controls[property].setErrors({ same: error });
					}
				});
		}
	}

  cancel() {
		this.dialogRef.close(false);
	}
}
