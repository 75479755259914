import {Component, OnInit, ViewChild, HostListener, OnDestroy} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { ContributionListComponent } from './contribution-list/contribution-list.component';
import {FiltersService} from "../services/filters.service";
import {SolrService} from "../services/solr.service";
import {FireworksDataSource} from "../services/datasources/fireworks.datasource";
import {merge} from "rxjs";
import {tap} from "rxjs/operators";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {ActivatedRoute, Router} from "@angular/router";
import {Meta, Title} from "@angular/platform-browser";
import {DataService} from "../services/data.service";
import {AuthService} from "../services/auth.service";
import {CscService} from "../services/csc.service";
import {ImagePopupComponent} from "../common/image-popup/image-popup.component";
import {VideoPopupComponent} from "../common/video-popup/video-popup.component";
import {TagsPopupComponent} from "../common/tags-popup/tags-popup.component";
import { Location } from '@angular/common';
import { LoadingPopupComponent } from '../common/loading/loading.component';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss']
})
export class SearchResultComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['score', 'name', 'video', 'image', 'brand', 'firework_type', 'duration', 'part_number', 'description'];
  dataSource: FireworksDataSource;
  public filters:any;
  public searching = false;
  public page:any;
  public user: any;
  loading = false;
  showAddToFireworksButton = true;
  tags = [];
  fireworkIds: any = [];
  subscriptions: any = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(public dialog: MatDialog,
              private filtersService: FiltersService,
              private solrService: SolrService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private titleService: Title,
              private metaService: Meta,
              private dataService: DataService,
              private authService: AuthService,
              private location: Location,
              private cscService: CscService) {
    this.dataSource = new FireworksDataSource(solrService);
    this.subscriptions.push(this.dataService.settings.subscribe((settings:any) => {
      if(!settings.pages) return;
      this.page = settings.pages.home;
    }));
    this.subscriptions.push(this.authService.userSubject.subscribe((user) => {
      this.user = user
    }));
    this.subscriptions.push(this.filtersService.filters.subscribe((filters: any) => {
      this.filters = filters;
      this.loadPage();
    }));
    this.subscriptions.push(this.dataSource.loading$.subscribe((status: boolean) =>{
      this.loading = status;
    }));
    this.subscriptions.push(this.cscService.showAddToFireworksButton.subscribe((status: boolean) => {
      this.showAddToFireworksButton = status;
      if(this.showAddToFireworksButton){
        this.cscService.addedFireworkIds.subscribe((fireworkIds: any) => {
          this.fireworkIds = fireworkIds;
          console.log(this.fireworkIds);
        });
      }
    }));
  }

  addToFireworkIds(firework: any){
    const dialogRef = this.dialog.open(LoadingPopupComponent,{
      id: 'loading-box',
      data: {
        message: 'Adding Fireworks to your collection...'
      },
    });
    this.cscService.addToMyFirework(firework.id, this.user ? this.user.id : null).subscribe((response: any) => {
      this.cscService.addAlreadyAddedFireworkIds(firework.id);
      dialogRef.componentInstance.loadingDone.emit({
        type: 'success',
        title: "Fireworks Added",
        message: 'Firework has been added to your collection successfully.'
      });
    }, (error: any) => {
      dialogRef.componentInstance.loadingDone.emit({
        type: 'error',
        title: "Error",
        message: 'There is an error adding the firework to your collection. Try again or contact our support.'
      });
    });
  }

  hasContribution(field_name: any, firework: any, user_only = false){
    return firework.contributions.some((contribution: any) => {
      return contribution.field_name === field_name && (contribution.approved_at && !user_only || this.user && this.user.id === contribution.user_id);
    });
  }

  getContribution(field_name: any, firework: any, user_only = false){
    if(this.hasContribution(field_name, firework, user_only)){
      return firework.contributions.filter((contribution: any) => {
        return contribution.field_name === field_name && (contribution.approved_at && !user_only || this.user && this.user.id === contribution.user_id);
      })[0].field_value;
    }
  }
  openContribution(firework: any) {
    const dialogRef = this.dialog.open(ContributionListComponent,{
      id: 'contribution-area',
      data: {
        firework: firework
      },
    });
  }

  openTag(firework: any) {
    const dialogRef = this.dialog.open(TagsPopupComponent,
      {
        id: 'tag-area',
        panelClass: 'model-dialog-area',
        maxWidth: '500px',
        width: '100%',
        data: {
          firework: firework
        },
    });
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.filters.orderBy = this.sort.active
          this.filters.order = this.sort.direction
          this.filters.page = this.paginator.pageIndex + 1;
          this.filters.per_page = this.paginator.pageSize;
          this.filtersService.setFilters(this.filters);
        })
      )
      .subscribe();
  }
  loadPage() {
    if(this.loading){ console.log("csvscsx");return; }
    let pageData = this.filtersService.parseFilters();
    let pageTitle = pageData.pageTitle;
    if(this.page) {
      if (pageData.pageTitle) {
        pageTitle += ' - ' + (this.page.meta_title ?? this.page.title)
      } else {
        pageTitle = this.page.meta_title ?? this.page.title;
      }
    }
    this.titleService.setTitle(pageTitle)
    // this.router.navigate(
    //   [''],
    //   { queryParams: pageData.queryParams }
    // );
    const url = this.router.createUrlTree([], {relativeTo: this.activatedRoute, queryParams: pageData.queryParams}).toString()
    console.log(url);
    this.location.go(url)

    this.dataSource.list(
      this.filters,
      this.filters.orderBy,
      this.filters.order,
      this.filters.page,
      this.filters.per_page);
  }
  ngOnInit(): void {
    
  }

  tagSelect(){
    setTimeout(() => {
      this.filters.ids = [];
      for(let i = 0; i < this.tags.length; i++){
        this.filters.ids = this.filters.ids.concat(this.user.tags[this.tags[i]].fireworks);  
      }
      this.filtersService.setFilters(this.filters);
    }, 100);
  }

  invertColor(hex:any) {
    if(!hex) return '#000000';
    hex = hex.hex ?? hex;
  	if (hex.indexOf('#') === 0) {
			hex = hex.slice(1);
		}
		// convert 3-digit hex to 6-digits.
		if (hex.length === 3) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}
		if (hex.length !== 6) {
			throw new Error('Invalid HEX color.');
		}
		let r = parseInt(hex.slice(0, 2), 16),
			g = parseInt(hex.slice(2, 4), 16),
			b = parseInt(hex.slice(4, 6), 16);
		return (r * 0.299 + g * 0.587 + b * 0.114) > 186
			? '#000000'
			: '#FFFFFF';
	}

  image_popup(item: any){
    const dialogRef = this.dialog.open(ImagePopupComponent, {
      id: 'image-popup',
      data: {
        image: (item.image ? (this.hasContribution('image', item, true) ? this.getContribution('image', item, true) : item.image) : this.getContribution('image', item)),
        title: item.name
      },
      maxHeight: '95vh'
    })
  }

  video_popup(item: any){
    console.log(item);
    const dialogRef = this.dialog.open(VideoPopupComponent, {
      id: 'video-popup',
      data: {
        video: item.video,
        title: item.name
      },
    })
  }

  ngOnDestroy() {
    for(let subscription of this.subscriptions){
      subscription.unsubscribe();
    }
  }
}
