import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http:HttpClient,
    private titleService: Title) { }

  settingsSubject = new BehaviorSubject<any>({});
  settings = this.settingsSubject.asObservable();

  getSettings(){
    let site = 'ignite';
    if(window.location.hostname.indexOf('wikifireworks.com') !== -1){
      site = 'wikifireworks'
    }
    this.http.get(environment.apiUrl + '/getSettings?site=' + site + '&public=1')
      .subscribe((response:any) => {
        this.titleService.setTitle(response.pages.home.title);
        this.settingsSubject.next(response);
      }, (error: any) => {
        this.settingsSubject.next(false);
    });
  }
}
