<h2 mat-dialog-title>Tag this firework</h2>
<mat-dialog-content class="mat-typography">
    <p>Use a tag to label and organize groups of your personal fireworks. For example, create a tag called "My Favorites" or "July 4th Show".</p>
    
    <div class="tag-field">

        <div class="data-tag-names">
            <div *ngFor="let userTag of user.tags; let i=index">
                <div class="data-tag-names-inner">
                    <mat-icon matPrefix class="my-icon border-left" *ngIf="!selected(i)" (click)="select(i)" matTooltip="Select this tag">check</mat-icon>
                    <mat-icon matPrefix class="my-icon border-left" *ngIf="selected(i)" (click)="deselect(i)" matTooltip="Deselect this tag">check_circle</mat-icon>
                    <mat-icon matPrefix class="my-icon" (click)="remove(i)" matTooltip="Remove this tag">close</mat-icon>
                    <mat-form-field appearance="outline" [style.background-color]="userTag.color">
                        <input [style.color]="invertColor(user.tags[i].color)"
                        matInput
                        [(ngModel)]="user.tags[i].name"
                    />
                    </mat-form-field>
                    <input style="display: none;"
                        matInput
                        [ngxMatColorPicker]="picker1"
                        autocomplete="off"
                        (click)="picker1.open()"
                        [(ngModel)]="user.tags[i].color"
                    />
                    <ngx-mat-color-toggle matSuffix [for]="picker1" matTooltip="Change Color of this tag"></ngx-mat-color-toggle>
                    <ngx-mat-color-picker
                        #picker1
                        [touchUi]="touchUi"
                        [color]="color"
                    ></ngx-mat-color-picker>
                </div>
            </div>
        </div>
    </div>
    
    <div class="custom-seperator">
        <div class="custom-seperator-border"></div>
        <span>or</span>
        <div class="custom-seperator-border"></div>
    </div>
    <div class="tag-field">
        <mat-icon matPrefix class="my-icon border-left" (click)="add()">check</mat-icon>
        <mat-form-field appearance="outline" [floatLabel]="'auto'" [style.background-color]="tag.color">
            <input [style.color]="invertColor(tag.color)"
            matInput
            [(ngModel)]="tag.name"
            autocomplete="off"
            placeholder="Enter New Tag Name"
          />
        </mat-form-field>
        <input style="display: none;"
            matInput
            [ngxMatColorPicker]="pickerNew"
            autocomplete="off"
            (click)="pickerNew.open()"
            [(ngModel)]="tag.color"
        />
        <ngx-mat-color-toggle matSuffix [for]="pickerNew"></ngx-mat-color-toggle>
        <ngx-mat-color-picker
            #pickerNew
            [touchUi]="touchUi"
            [color]="color"
        ></ngx-mat-color-picker>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="tag-popup" align="end">
    <button mat-raised-button color="primary" (click)="save()"><mat-icon *ngIf="processing">
            <mat-spinner color="warn" diameter="20"></mat-spinner>
          </mat-icon> Save & Close</button>
    <button mat-button mat-dialog-close class="silver">Cancel</button>
</mat-dialog-actions>