<mat-expansion-panel [expanded]="filters.expanded.duration" (opened)="opened()" (closed)="closed()">
  <mat-expansion-panel-header>
    <mat-panel-title>By Duration</mat-panel-title>
  </mat-expansion-panel-header>
  <!-- <mat-slider color="accent" id="to-slider" aria-label="duration(s)" class="w-100" [(ngModel)]="filters.duration.max" [min]="min" [max]="max" thumbLabel="true"  #ngSlider><input matSliderThumb (change)="changed()" #ngSliderThumb="matSliderThumb" /></mat-slider>
  <mat-slider color="accent" id="from-slider" aria-label="duration(s)" class="w-100" [(ngModel)]="filters.duration.min" [min]="min" [max]="max" thumbLabel="true" #ngSlider><input matSliderThumb (change)="changed()" #ngSliderThumb="matSliderThumb" /></mat-slider> -->
  <mat-slider color="primary" [min]="min" [max]="max" style="width: 210px;" (change)="changed()">
    <input [(ngModel)]="filters.duration.min" matSliderStartThumb>
    <input [(ngModel)]="filters.duration.max" matSliderEndThumb>
  </mat-slider>
  <div class="range_value" rev="sec" step="1">{{ filters.duration.min }} sec - {{ filters.duration.max }} sec</div>
</mat-expansion-panel>
