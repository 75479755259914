import { Component, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingPopupComponent {
  public processing = true;
  public message = '';
  public title = '';
  public type = '';
  loadingDone = new EventEmitter();
  constructor(private dialogRef: MatDialogRef<LoadingPopupComponent>,
      @Inject(MAT_DIALOG_DATA) data:any ) {
    this.processing = true;
    if(data.title){
      this.title = data.title;
    }
    if(data.message){
      this.message = data.message;
    }
  }

  ngOnInit(): void {
    this.loadingDone.subscribe(data=> {
      if(data.title){
        this.title = data.title;
      }
      if(data.message){
        this.message = data.message;
      }
      if(data.type){
        this.type = data.type;
      }
      this.processing = false;
    });
  }

  ngOnChange(): void {
  }

  close() {
    this.dialogRef.close(false);
  }
}
