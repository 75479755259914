<div class="search-area">
    <div class="row">
        <div class="col-md-5">
            <div class="search-input">
                <mat-form-field appearance="outline" class="search-field">
                  <input type="text" #search placeholder="Search" matInput (keydown.enter)="searchFreeText()" [formControl]="searchControl" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" >
                      <mat-option *ngFor="let option of filteredData" (click)="autoCompleteFilter(option)">
                        {{ option.term }}
                      </mat-option>
                    </mat-autocomplete>
                  <button *ngIf="searchControl.value" class="search-remove-icon" mat-mini-fab color="primary" aria-label="Remove search term" (click)="clear()"><mat-icon>close</mat-icon></button>
                </mat-form-field>
            </div>
        </div>
        <div class="col-md-5 pl-0">
            <div class="search-filter">
                <mat-checkbox color="primary" (change)="toggle('has_video', $event.checked)" [checked]="filters.has_video">Has Video</mat-checkbox>
              <mat-checkbox color="primary" (change)="toggle('has_duration', $event.checked)" [checked]="filters.has_duration">Has Duration</mat-checkbox>
            </div>
        </div>
    </div>
</div>
