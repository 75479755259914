import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ContributionService {

  constructor(private http:HttpClient) { }

  index(filters:any, orderBy:string, order:string,
         pageNumber:number, pageSize:number): Observable<any>{
    let params = new HttpParams();
    Object.keys(filters).forEach(function (key) {
      params = params.append(key, filters[key]);
    });
    params = params.append('order_by', orderBy);
    params = params.append('order', order);
    params = params.append('page', pageNumber);
    params = params.append('per_page', pageSize);
    return this.http.get(environment.apiUrl + '/contributions', {params: params})
  }
  saveContribution(fireworkId: any, field: any, value: any){
    const formData = new FormData();
    formData.append("value", value);
    formData.append("field", field);
    return this.http.post(environment.apiUrl + '/general/' + fireworkId + '/contributions', formData)
  }
  pageData(){
    return this.http.get(environment.apiUrl + '/contribution-page-data');
  }
}
