import {Firework} from "../../interfaces/firework.interface";
import {CollectionViewer, DataSource} from "@angular/cdk/collections";
import {BehaviorSubject, Observable, of} from "rxjs";
import {catchError, finalize} from "rxjs/operators";
import {SolrService} from "../solr.service";

export class FireworksDataSource implements DataSource<Firework> {

	public fireworksSubject = new BehaviorSubject<Firework[]>([]);
	private loadingSubject = new BehaviorSubject<boolean>(false);
	private countSubject = new BehaviorSubject<number>(0);
	public currentPageCountSubject = new BehaviorSubject<number>(0);

	public loading$ = this.loadingSubject.asObservable();
	public count$ = this.countSubject.asObservable();
	public currentPageCount$ = this.currentPageCountSubject.asObservable();

	constructor(private solrService: SolrService) {}

	connect(collectionViewer: CollectionViewer): Observable<Firework[]> {
		return this.fireworksSubject.asObservable();
	}

	disconnect(collectionViewer: CollectionViewer): void {
		this.fireworksSubject.complete();
		this.loadingSubject.complete();
		this.countSubject.complete();
		this.currentPageCountSubject.complete();
	}

	list(filters = {}, orderBy = 'score', order = 'desc',
						pageNumber = 0, pageSize = 5) {

		this.loadingSubject.next(true);

		this.solrService.search(filters, orderBy, order,
			pageNumber, pageSize).pipe(
			catchError(() => of([])),
			finalize(() => this.loadingSubject.next(false))
		)
			.subscribe(response => {
				this.fireworksSubject.next(response.records);
        this.solrService.defaults.next(response.defaults);
				this.countSubject.next(response.total);
				this.currentPageCountSubject.next(response.records.length);
			});
	}

	getList() {
		return this.fireworksSubject.value;
	}
}
