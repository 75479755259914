<mat-toolbar color="primary">
    <div class="logo">
        <a href="/"><img src="/assets/img/logo-{{ env.site }}.png" class="logo-{{ env.site }}" /></a>
    </div>
    <div class="mobile-menu" *ngIf="settings.pages">
        <button mat-button [matMenuTriggerFor]="menu"><mat-icon >menu</mat-icon></button>
        <mat-menu #menu="matMenu">
          <span *ngFor="let menu of settings.pages.menu">
            <span *ngIf="menu.children.length > 0">
              <button mat-menu-item [matMenuTriggerFor]="childMenu">{{ menu.page.title }}</button>
              <mat-menu #childMenu="matMenu">
                <button *ngFor="let submenu of menu.children" mat-menu-item [routerLink]="submenu.slug">{{ submenu.title }}</button>
              </mat-menu>
            </span>
            <span *ngIf="menu.children.length == 0">
              <button mat-menu-item [routerLink]="menu.page.slug">{{ menu.page.title }}</button>
            </span>
          </span>
          <button *ngIf="!user" mat-menu-item routerLink="login">Login</button>
          <button *ngIf="user" mat-menu-item routerLink="login">{{ user.name }}</button>
        </mat-menu>
    </div>
    <div class="menu" *ngIf="settings.pages">
        <ul>
            <li *ngFor="let menu of settings.pages.menu" [ngClass]="{'dropdown': menu.children.length > 0}">
              <a [routerLink]="menu.children.length > 0 ? undefined : menu.page.slug" [ngClass]="{'dropdown-toggle': menu.children.length > 0}">{{ menu.page.title }}</a>
              <ul *ngIf="menu.children.length > 0" class="dropdown-menu">
                <li *ngFor="let submenu of menu.children"><a [routerLink]="submenu.slug">{{ submenu.title }}</a></li>
              </ul>
            </li>
            <li *ngIf="!user"><a routerLink="login">Login</a></li>
            <li *ngIf="user" class="dropdown">
              <a class="dropdown-toggle" href="">{{ user.name }}</a>
              <ul class="dropdown-menu">
                <li>
                  <a href="https://admin.ignitefiringsystems.com/app" target="_blank" *ngIf="user.is_admin">Admin</a></li>
                <li>
                  <a (click)="logout()">Logout</a>
                </li>
              </ul>
            </li>
        </ul>
    </div>
  </mat-toolbar>
<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport
        [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        [opened]="(isHandset$ | async) === false && !fullPageTemplate">
        <div class="sidenav-close">
          <button mat-icon-button (click)="drawer.close()" class="close-icon"><mat-icon>close</mat-icon></button>
        </div>
        <mat-accordion class="sidebar-list" multi>
            <app-duration></app-duration>
            <app-country></app-country>
            <app-regulations></app-regulations>
            <app-types></app-types>
            <app-brands></app-brands>
        </mat-accordion>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar color="secondary">
          <div class="mobile-menu-icon-area">
            <button class="mobile-menu-icon"
                type="button"
                aria-label="Toggle sidenav"
                mat-icon-button
                (click)="drawer.toggle()"
                *ngIf="isHandset$ | async">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <span *ngIf="isHandset$ | async" class="mobile-menu-icon-text">Filters</span>
          </div>
          <div class="select-filter">
              <mat-chip-listbox aria-label="Country selection" *ngIf="defaults.countries">
                <mat-chip-option *ngIf="filters.duration.min > 0">Min. Duration: {{ filters.duration.min }}(s) <button matChipRemove (click)="removeMinDuration()"><mat-icon>cancel</mat-icon></button></mat-chip-option>
                <mat-chip-option *ngIf="filters.duration.max < 60">Max. Duration: {{ filters.duration.max }}(s) <button matChipRemove (click)="removeMaxDuration()"><mat-icon>cancel</mat-icon></button></mat-chip-option>
                <mat-chip-option *ngFor="let country of filters.countries">{{ country }} <button matChipRemove (click)="remove(country, 'countries')"><mat-icon>cancel</mat-icon></button></mat-chip-option>
                <mat-chip-option *ngFor="let regulation of filters.regulations">{{ getObjectById(defaults.regulations, regulation).record.title }} <button matChipRemove (click)="remove(regulation, 'regulations')"><mat-icon>cancel</mat-icon></button></mat-chip-option>
                <mat-chip-option color="accent" *ngFor="let fireworkType of filters.firework_types">{{ fireworkType }} <button matChipRemove (click)="remove(fireworkType, 'firework_types')"><mat-icon>cancel</mat-icon></button></mat-chip-option>
                <mat-chip-option color="primary" *ngFor="let brand of filters.brands">{{ brand }} <button matChipRemove (click)="remove(brand, 'brands')"><mat-icon>cancel</mat-icon></button></mat-chip-option>
              </mat-chip-listbox>
          </div>
        </mat-toolbar>
        <!-- Add Content Here -->
        <div class="content" [class]="{'full-page': fullPageTemplate}">
          <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>