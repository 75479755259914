<div class="create-form-search" *ngIf="loaded">
    <mat-form-field appearance="outline">
        <mat-label>Search</mat-label>
        <mat-select (selectionChange)="loadSavedSearch()" [(ngModel)]="savedSearch">
          <mat-option *ngIf="user.saved_searches.length == 0" value="">No saved searches.</mat-option>
          <mat-option *ngIf="user.saved_searches.length > 0" [value]="null">Select a saved search...</mat-option>
          <mat-option *ngFor="let saved_search of user.saved_searches" [value]="saved_search">{{ saved_search.name }}</mat-option>
        </mat-select>
    </mat-form-field>
    <button mat-button color="primary" (click)="newSavedSearch()">Create New</button>
    <button mat-button color="primary" *ngIf="savedSearch" [disabled]="saving" (click)="saveSavedSearch()">Save <mat-icon *ngIf="saving">
      <mat-spinner color="primary" diameter="20"></mat-spinner>
    </mat-icon></button>
    <button mat-button color="primary" *ngIf="savedSearch" [disabled]="deleting" (click)="deleteSavedSearch()"> <mat-icon *ngIf="deleting">
      <mat-spinner color="primary" diameter="20"></mat-spinner>
    </mat-icon>Delete</button>
</div>